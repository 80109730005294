import { useMutation } from '@tanstack/react-query';
import { URL_CONF } from '../helper/constants';
import gqlConfig from '../helper/gqlConfig';
import { MutationResponseType, QueryErrorResponse } from '../types/UserDetailType';
import { fetchLogObj } from '../helper/log';
import userQueries from '../queries/user';

type Config = {
  onError?: ({ response }: QueryErrorResponse) => void;
  onSuccess?: (data: MutationResponseType) => void;
  enabled?: boolean;
};

export const useGQLMutation = (key: string, query: string, config?: Config, path?: string) => {
  const apiPath = path || URL_CONF.DEFAULT;
  // const apiPath = path || URL_CONF.DEFAULT;
  const graphQLClient = gqlConfig(apiPath);

  const onError = () => {
    // const { message } = response.errors[0];
    // const defaultMessage = 'Something went wrong!';
  };

  // const fetchData = async (variables = {}) => graphQLClient.request<MutationResponseType>(query, variables);

  /**
   * Function to fetch the data using graphQLClient
   * @param variables
   * @returns data
   */
  const fetchData = async (variables = {}) => {
    const data = await graphQLClient.request<MutationResponseType>(query, variables);
    // add logs
    const logReq = fetchLogObj(key);
    if (logReq) {
      const input = JSON.stringify(logReq);
      const logQuery: string = userQueries.CREATE_LOG();
      const defaultGraphqlClient = gqlConfig(URL_CONF.DEFAULT);
      await defaultGraphqlClient.request(logQuery, { user_logs: input });
    }

    return data;
  };

  const defaultQueryConfig = { refetchOnWindowFocus: false, enabled: false };
  // return useMutation(key, fetchData, {
  //     ...defaultQueryConfig,
  //     ...config,
  //     onError: config?.onError ? config.onError : onError,
  // });

  return useMutation({
    mutationKey: [key] as unknown as unknown[],
    mutationFn: fetchData,
    ...defaultQueryConfig,
    ...config,
    onError: config?.onError ? config.onError : onError,
  });
};
