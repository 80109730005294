import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    input: {
      '&::-ms-reveal, &::-ms-clear': {
        display: 'none',
      },
    },
  },
  rightMobileSection: {
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '0px',
    },
  },
  headerPassword: {
    fontSize: '25px',
    backgroundColor: '#F6F6F6',
    height: '149px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '10px 10px 0px 0px',
    paddingLeft: '15px',
  },
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    boxShadow: '24',
    borderRadius: '10px',
  },
  rightHeader: {
    backgroundColor: 'rgba(246, 246, 246, 1)',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '30px',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    height: '178px',
    [theme.breakpoints.between('lg', 'xl')]: {
      height: '100px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      height: '40px',
      paddingBottom: '15px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: '25px',
      paddingBottom: '8px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      height: 'auto',
      paddingBottom: '15px',
      backgroundColor: '#fff',
      display: 'block',
      position: 'fixed',
      width: '100%',
      zIndex: 999,
      top: 0,
    },
    '& h1': {
      color: 'rgba(31, 31, 31, 1)',
      fontSize: '25px',
      textAlign: 'center',
      marginLeft: '-100px',
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '16px',
        marginLeft: '-200px',
      },
    },
    '& p': {
      color: 'rgba(153, 158, 161, 1)',
      marginTop: '12px',
      textAlign: 'center',
      [theme.breakpoints.between('xs', 'sm')]: {
        textAlign: 'left',
      },
    },
  },
  rightHeaderForgot: {
    height: '178px',
    [theme.breakpoints.between('lg', 'xl')]: {
      height: '100px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      height: 'auto',
    },
  },
  rightHeaderChange: {
    height: '130px',
    [theme.breakpoints.between('lg', 'xl')]: {
      height: '100px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      height: 'auto',
    },
  },
  rightHeadWraper: {
    margin: '0px auto',
    maxWidth: '360px',
    [theme.breakpoints.between('xs', 'sm')]: {
      maxWidth: '100%',
    },
    '& p': {
      textAlign: 'left',
    },
  },
  rightHeaderMobileSection: {
    display: 'none',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'block',
      padding: '24px 15px 24px 15px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      '& h2': {
        paddingLeft: '0px !important',
      },
    },
    '& h2': {
      fontFamily: 'Inter-Bold',
      position: 'relative',
      paddingLeft: '10%',
      '& img': {
        float: 'right',
        marginTop: '-3px',
      },
      '& span': {
        '&:nth-child(1)': {
          position: 'absolute',
          left: '0px',
          cursor: 'pointer',
          top: '50%',
          transform: 'translateY(-44%)',
          '& svg': {
            color: 'rgba(26, 76, 142, 1)',
            fontSize: '37px',
          },
        },
      },
    },
  },
  rightBody: {
    maxWidth: '360px',
    margin: '0px auto',
    padding: '100px 0px 0px 0px',
    [theme.breakpoints.between('md', 'lg')]: {
      padding: '30px 0px 0px 0px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '20px 0px 0px 0px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      maxWidth: 'unset',
      padding: '72px 15px 50px 15px',
    },
  },
  rightBodyChange: {
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '72px 15px 50px 15px',
    },
    paddingTop: '50px',
  },
  signUprightBody: {
    maxWidth: 'unset',
    margin: 'unset',
    padding: '65px 50px 20px 50px',
    [theme.breakpoints.between('md', 'lg')]: {
      padding: '65px 30px 20px 30px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '65px 10px 20px 10px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      maxWidth: 'unset',
      padding: '0px',
      marginTop: '44px',
    },
    '& .MuiGrid-item': {
      paddingTop: '0px !important',
    },
  },
  signupAlign: {
    [theme.breakpoints.between('xs', 'sm')]: {
      maxWidth: 'unset',
      padding: '72px 15px 50px 15px',
    },
  },
  rightBodyInput: {
    marginBottom: '30px',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: '20px',
    },
    '& p': {
      textAlign: 'right',
      color: 'rgba(156, 0, 21, 1)',
      marginTop: '7px',
      cursor: 'pointer',
      '& a': {
        color: 'rgba(156, 0, 21, 1)',
      },
    },
    '& input': {
      fontFamily: 'Inter-Medium',
      padding: '10.4px 14px',
      fontSize: '16px',
      color: 'rgba(31, 31, 31, 1)',
    },
    '& label': {
      color: 'rgba(26, 76, 142, 1)',
      marginBottom: '5px',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      borderRadius: '10px',
    },
    '& fieldset': {
      border: '1px solid rgba(198, 198, 198, 1) !important',
    },
  },
  rightBodyInputMobile: {
    display: 'none',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'block',
    },
  },
  rightBodyInputPop: {
    padding: '15px 15px 10px 15px',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: '20px',
    },
    '& p': {
      textAlign: 'right',
      color: 'rgba(156, 0, 21, 1)',
      marginTop: '7px',
      cursor: 'pointer',
      '& a': {
        color: 'rgba(156, 0, 21, 1)',
      },
    },
    '& input': {
      fontFamily: 'Inter-Medium',
      padding: '10.4px 14px',
      fontSize: '14px',
      color: 'rgba(31, 31, 31, 1)',
    },
    '& label': {
      color: 'rgba(26, 76, 142, 1)',
      marginBottom: '5px',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      borderRadius: '10px',
    },
    '& fieldset': {
      border: '1px solid rgba(198, 198, 198, 1) !important',
    },
  },
  borderStyle: {
    '& fieldset': {
      border: '1px solid rgba(198, 198, 198, 1) !important',
    },
  },
  rightBodyButton: {
    '& button': {
      color: '#fff',
      backgroundColor: 'rgba(25, 104, 182, 1)',
      borderRadius: '10px',
      cursor: 'pointer',
      textAlign: 'center',
      padding: '12px 0px',
      border: 'none',
      width: '100%',
      fontSize: 17,
      fontFamily: 'Inter-Medium',
    },
  },
  rightBodyButtonPop: {
    padding: '20px 15px',
    '& button': {
      color: '#fff',
      backgroundColor: 'rgba(25, 104, 182, 1)',
      borderRadius: '10px',
      cursor: 'pointer',
      textAlign: 'center',
      padding: '12px 0px',
      border: 'none',
      width: '100%',
      fontSize: 17,
      fontFamily: 'Inter-Medium',
    },
  },
  rightBodyButtonDisabled: {
    '& button': {
      color: '#fff',
      backgroundColor: 'rgba(191, 207, 231, 1)',
      borderRadius: '10px',
      textAlign: 'center',
      padding: '12px 0px',
      border: 'none',
      width: '100%',
      fontSize: 17,
      fontFamily: 'Inter-Medium',
    },
  },
  rightBodyOrWith: {
    '& p': {
      color: 'rgba(153, 158, 161, 1)',
      borderBottom: '.5px solid rgba(205, 209, 224, 1)',
      textAlign: 'center',
      margin: '35px 0px',
      lineHeight: '1.6px',
      [theme.breakpoints.between('sm', 'md')]: {
        margin: '15px 0px',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        margin: '15px 0px',
      },
      '& span': {
        backgroundColor: '#fff',
        padding: '0px 10px',
      },
    },
  },
  authImg: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      background: 'none',
      border: 'none',
      padding: 0,
      margin: 0,
      cursor: 'pointer',
      '& img': {
        '&:nth-child(2)': {
          marginRight: '0px',
        },
      },
    },
  },
  authContent: {
    textAlign: 'center',
    marginTop: '30px',
    color: 'rgba(153, 158, 161, 1)',
    [theme.breakpoints.between('xs', 'sm')]: {
      position: 'fixed',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '15px',
      width: '100%',
      backgroundColor: '#fff',
      padding: '10px',
      zIndex: '999',
      '& p': {
        fontSize: '17px',
      },
    },
    '& span': {
      color: 'rgba(26, 76, 142, 1)',
      marginLeft: '10px',
      cursor: 'pointer',
      fontFamily: 'Inter-Medium',
      '& a': {
        color: 'rgba(26, 76, 142, 1)',
      },
    },
  },
  errorStyle: {
    color: 'red',
    fontSize: '12px',
  },

  telephoneBoxStyle: {
    '& input': {
      '&.form-control': {
        borderRadius: '10px',
        lineHeight: 'normal',
        height: 'auto',
        padding: '10.9px 14px 10.9px 48px',
        width: '100%',
        [theme.breakpoints.between('xs', 'sm')]: {
          fontSize: '16px',
        },
      },
    },
    '& .selected-flag': {
      borderRadius: '10px 0 0 10px',
    },
    '& .flag-dropdown': {
      borderRadius: '10px 0 0 10px',
      backgroundColor: '#fff',
    },
  },
  tabHeaderStyle: {
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'none',
    },
    '& .MuiTabs-fixed': {
      margin: '14px 50px',
      backgroundColor: 'rgba(235, 235, 235, 1)',
      borderRadius: '10px',
      padding: '4px 5px',
      [theme.breakpoints.between('md', 'lg')]: {
        margin: '14px 30px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        margin: '14px 10px',
      },
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& button': {
      width: '240px',
      height: '37px',
      textTransform: 'capitalize',
      color: 'rgba(84, 84, 84, 1)',
      fontSize: '17px',
      minHeight: 'auto',
      [theme.breakpoints.between('md', 'lg')]: {
        width: '190px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: 'auto',
      },
      '&.Mui-selected': {
        backgroundColor: '#fff',
        color: 'rgba(25, 104, 182, 1)',
        borderRadius: '10px',
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  checkboxStyle: {
    marginBottom: '30px',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: '20px',
    },
    '& input': {
      padding: '0',
      height: 'initial',
      width: 'initial',
      marginBottom: '0',
      display: 'none',
      cursor: 'pointer',
    },
    '& label': {
      paddingLeft: '5px',
      color: 'rgba(31, 31, 31, 1)',
      fontSize: '14px',
      position: 'relative',
      cursor: 'pointer',
      '& span': {
        textDecoration: 'underline',
        marginLeft: '4px',
      },
    },
  },
  selectBoxInMobile: {
    display: 'none',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'block',
      marginBottom: '40px',
      '& .MuiSelect-select': {
        padding: '10.4px 14px',
        color: 'rgba(31, 31, 31, 1)',
        fontFamily: 'Inter-Medium',
      },
      '& .MuiInputBase-root': {
        borderRadius: '10px',
      },
      '& label': {
        color: 'rgba(26, 76, 142, 1)',
        marginBottom: '5px',
      },
    },
  },
  backReturnButton: {
    marginTop: '30px',
    textAlign: 'center',
    color: 'rgba(26, 76, 142, 1)',
    '& p': {
      cursor: 'pointer',
      '& a': {
        color: 'rgba(26, 76, 142, 1)',
      },
    },
    '& span': {
      marginRight: '5px',
      verticalAlign: 'sub',
    },
  },
  desktopHeadtext: {
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'none',
    },
  },
  mobileHeadText: {
    display: 'none',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'block',
      paddingTop: '35px',
      paddingBottom: '12px',
    },
  },
  mobileSmText: {
    color: 'rgba(153, 158, 161, 1)',
  },
  headerAccountStyle: {
    padding: '15px 0px 25px 15px',
    backgroundColor: 'rgba(246, 246, 246, 1)',
  },
  modelStyle: {
    '& .MuiDialog-container': {
      [theme.breakpoints.between('xs', 'sm')]: {
        alignItems: 'flex-end',
      },
    },
    '& .MuiDialog-paper': {
      width: '407px',
      borderRadius: '14px',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
        margin: '0px',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '20px 20px 0px 0px',
      },
      '& img': {
        margin: '0px auto',
      },
      '& h2': {
        color: 'rgba(31, 31, 31, 1)',
        fontFamily: 'Inter-Bold',
        marginTop: '30px',
        [theme.breakpoints.between('xs', 'sm')]: {
          marginTop: '12px',
        },
      },
      '& h6': {
        color: 'rgba(143, 143, 143, 1)',
        marginTop: '2px',
      },
      '& h5': {
        padding: '0px 15px 0px 0px',
        textAlign: 'right',
        '& svg': {
          color: 'rgba(158, 158, 158, 1)',
          fontSize: '23px',
          cursor: 'pointer',
        },
      },
    },
  },
  sectionBodyDrop: {
    '& .MuiFormControl-root': {
      minWidth: '100%',
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
      },
    },
    '& .MuiSelect-select': {
      padding: '9.45px 10px',
      fontSize: '16px',
      borderRadius: '10px',
      color: '#605F5F',
      border: '1px solid #fdfdfd',
      backgroundColor: '#ffffff',
      alignItems: 'center',
      display: 'flex',
    },
    '& button': {
      lineHeight: '0',
      padding: '20.5px 16px',
      textTransform: 'capitalize',
      fontSize: '16.67px',
      fontWeight: '500',
    },
  },
}));

export default useStyles;
