import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Grid, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { NavLink, useNavigate } from 'react-router-dom';
import back from '../../../assets/back_Color.svg';
import vpLogo from '../../../assets/vplogos.svg';
import Loader from '../../../components/loader';
import SuccessPopup from '../../../components/successpopup';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import userQueries from '../../../queries/user';
import { QueryErrorResponse } from '../../../types/UserDetailType';
import useStyles from '../Auth.style';
import { useQueryClient } from '@tanstack/react-query';
import { PATH_AUTH } from '../../../routers/path';
import { capitalize, emailValidator } from '../../../helper/validators';
import { KEY_CONF, URL_CONF } from '../../../helper/constants';
import { IS_MOBILE } from '../../../theme/constants';

function ForgetPassword() {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [userEmail, setUserEmail] = useState<string>('');
  const [errorUser, setErrorUser] = useState<string>('');
  const [showPasswordRecoveryPopup, setPasswordRecoveryPopup] = useState<boolean>(false);
  const PAGE_TITLE = IS_MOBILE ? 'Authentication' : 'Forgot Password?';

  // Error handling---------------------------------------------
  const handleApiError = ({ response }: QueryErrorResponse) => {
    const message =
      response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
    if (message) {
      toast.error(message);
    }
    return true;
  };

  // handling forgot password Query using GQL
  const isCall = false;
  const {
    data: response,
    isFetching,
    refetch,
    isError,
    error,
  } = useGQLQuery(
    [KEY_CONF.FORGOT_PASSWORD],
    userQueries.FORGOT_PASSWORD(userEmail),
    {},
    {
      enabled: isCall,
    },
    URL_CONF.AUTHENTICATION,
  );
  //-----------------------------------------------------

  useEffect(() => {
    if (isError && error !== null) {
      handleApiError(error);
    }
  }, [error, isError]);

  // forgot password response handling
  useEffect(() => {
    if (response && !isFetching) {
      setPasswordRecoveryPopup(true);
    }
  }, [isFetching, response]);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserEmail(value);
    if (value.trim().length === 0) {
      setErrorUser(`${capitalize(name)} is required`);
    } else if (emailValidator(value)) {
      setErrorUser('');
    } else {
      setErrorUser('Enter Valid Email Id');
    }
  };

  // const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     setUserEmail(e.target.value);
  //     const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
  //     const result = regex.test(e.target.value);
  //     const inputStyle = document.getElementsByTagName('fieldset')[0];
  //     if (!result) {
  //         setErrorUser('Enter Valid Email Id');
  //         inputStyle.style.border = '2px solid red';
  //     } else {
  //         setErrorUser('');
  //         inputStyle.style.border = '2px solid rgba(26, 76, 142, 1)';
  //     }
  // };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userEmail) {
      setErrorUser('Email is required');
    } else if (!errorUser) {
      refetch();
    }
  };

  const handleClose = () => {
    setPasswordRecoveryPopup(false);
    navigate(PATH_AUTH.children.login);
    // clears all connected caches
    queryClient.clear();
  };

  return (
    <Grid item xs={12} sm={6}>
      {isFetching && <Loader />}
      {showPasswordRecoveryPopup && (
        <SuccessPopup
          open={showPasswordRecoveryPopup}
          handleClose={handleClose}
          title="A password recover link has been sent to your e-mail."
          subtitle="Did not receive the email? Check your spam folder"
        />
      )}
      <Box className={classes.rightMobileSection}>
        <Box className={`${classes.rightHeader} ${classes.rightHeaderForgot}`}>
          <Box className={classes.rightHeaderMobileSection}>
            <Typography variant="h2">
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <a href="https://virtualpaysolutions.com/" aria-label="Go back">
                  <ChevronLeftIcon />
                </a>
                <span>Forgot Password</span>
              </span>
              <span>
                <img src={vpLogo} alt="vpLogo" />
              </span>
            </Typography>
          </Box>
          <Box className={classes.desktopHeadtext}>
            <Typography variant="h1">{PAGE_TITLE}</Typography>
            <Typography variant="body1">
              Enter your email address to get instructions for
              <br />
              resetting your password.
            </Typography>
          </Box>
        </Box>

        <Box className={classes.rightBody}>
          <Box className={classes.mobileHeadText}>
            <Typography variant="h1">{PAGE_TITLE}</Typography>
            <Typography variant="body1" className={classes.mobileSmText}>
              Enter your email address to get instructions for resetting your password.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box className={classes.rightBodyInput}>
              <InputLabel>Email *</InputLabel>
              <TextField
                id="outlined-basic-email"
                type="email"
                name="email"
                value={userEmail}
                autoComplete="off"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChangeEmail(e)}
                placeholder="Please Enter Your Email"
                variant="outlined"
                className={userEmail.length > 0 ? classes.borderStyle : ''}
              />
              <span className={classes.errorStyle}>{errorUser}</span>
            </Box>
            <Box className={classes.rightBodyButton}>
              <button type="submit">Reset Password</button>
            </Box>
            <Box className={classes.backReturnButton}>
              <Typography variant="body1">
                <NavLink to="/login">
                  <span>
                    <img src={back} alt="back" />
                  </span>
                  Return to login
                </NavLink>
              </Typography>
            </Box>
          </form>
        </Box>
      </Box>
    </Grid>
  );
}
export default ForgetPassword;
