import { Box, Typography } from '@mui/material';
import logo from '../../../../assets/logo_login.svg';
import back from '../../../../assets/back.svg';
import React from 'react';
import useLeftBannerStyles from './LeftBanner.style';
import { NavLink } from 'react-router-dom';

type StyleProps = {
  loginPageStyle: boolean;
};

function LeftBanner({ loginPageStyle }: StyleProps) {
  const HOMEPAGE = process.env.REACT_APP_VIRTUAL_PAY_URL;
  const classes = useLeftBannerStyles();
  return (
    <Box
      className={
        loginPageStyle ? `${classes.loginPageLeftBanner} ${classes.leftBanner}` : classes.leftBanner
      }
    >
      <Box className={classes.innerLeftBanner}>
        <img src={logo} alt={logo} />
        <Typography variant="h1">
          Your trusted
          <br />
          Crypto Partner
        </Typography>
        <Box className={classes.bottomSetion}>
          <Typography variant="h2">
            <span>
              <img src={back} alt="back" />
            </span>
            <span>
              <NavLink style={{ color: 'white' }} to={`${HOMEPAGE}`}>
                Go back
              </NavLink>
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default LeftBanner;
