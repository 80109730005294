// public.tsx
import { Navigate } from 'react-router-dom';
import { getAccessTokenFromLocalStorage } from '../helper/storageHelper';
import { IRoute } from '../interfaces/Route';
import Auth from '../screens/auth';
import ChangePassword from '../screens/auth/changepassword';
import ForgetPassword from '../screens/auth/forgotpassword';
import InvalidRequest from '../screens/auth/invalidrequest';
import Login from '../screens/auth/login';
import ResetPassword from '../screens/auth/resetpassword';
import Signup from '../screens/auth/signup';
import { PATH_AUTH } from './path';

export default function PublicRouter(): IRoute[] {
  const isAuthenticated = getAccessTokenFromLocalStorage();

  return isAuthenticated
    ? []
    : [
        // Route definitions for unauthenticated users
        {
          path: PATH_AUTH.auth,
          element: <Auth />,
          children: [
            {
              path: PATH_AUTH.children.login,
              element: <Login />,
            },
            {
              path: PATH_AUTH.children.signup,
              element: <Signup />,
            },
            {
              path: PATH_AUTH.children.forgotPassword,
              element: <ForgetPassword />,
            },
            {
              path: PATH_AUTH.children.ChangePassword,
              element: <ChangePassword />,
            },
            {
              path: PATH_AUTH.children.resetPassword,
              element: <ResetPassword />,
            },
            {
              path: PATH_AUTH.children.invalidRequest,
              element: <InvalidRequest />,
            },
            {
              path: '/',
              element: <Navigate to={PATH_AUTH.children.login} />,
            },
          ],
        },
      ];
}
