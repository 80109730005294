import { Close, TaskAlt } from '@mui/icons-material';
import { Button, Theme, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import verifyEmail from '../../../assets/verifyEmailLogo.png';
import { useGQLMutation } from '../../../hooks/useGQLMutation';
import userQueries from '../../../queries/user';
import { QueryErrorResponse } from '../../../types/UserDetailType';
import Loader from '../../../components/loader';
import { PATH_AUTH } from '../../../routers/path';
import { useQueryClient } from '@tanstack/react-query';
import { URL_CONF } from '../../../helper/constants';

type PopupProps = {
  open: boolean;
  handleClose: VoidFunction;
  token: string;
};

const popupStyle = makeStyles((theme: Theme) => ({
  popStyle: {
    textAlign: 'center',
  },
  modelStyle: {
    '& .MuiDialog-paper': {
      width: '545px',
      borderRadius: '14px',
      padding: '40px 0px 120px 0px',
      textAlign: 'center',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
        margin: '0px',
        borderRadius: '0px',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
      },
      '& img': {
        margin: '0px auto',
        height: '25%',
        width: '25%',
      },
      '& h2': {
        color: 'rgba(31, 31, 31, 1)',
        fontFamily: 'Inter-Bold',
        marginTop: '30px',
      },
      '& h6': {
        color: 'rgba(143, 143, 143, 1)',
        marginTop: '2px',
      },
      '& h5': {
        padding: '0px 35px 0px 0px',
        textAlign: 'right',
        '& svg': {
          color: 'rgba(158, 158, 158, 1)',
          fontSize: '30px',
          cursor: 'pointer',
        },
      },
      '& button': {
        margin: '20px auto',
      },
    },
  },
}));

function VerifyEmailPopup({ open, handleClose, token }: PopupProps) {
  const classes = popupStyle();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  // Error handling---------------------------------------------
  const handleApiError = ({ response }: QueryErrorResponse) => {
    const message =
      response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
    if (message) {
      toast.error(message);
    }
    setLoading(false);
  };

  const { mutate } = useGQLMutation(
    'verifyEmail',
    userQueries.VERIFY_EMAIL(token),
    {
      onSuccess: (data) => {
        setLoading(false);
        const { verify_email: validEmail } = data;
        toast.success(validEmail.message);
        handleClose();
        navigate(PATH_AUTH.children.login);
        // clears all connected caches
        queryClient.clear();
      },
      onError: handleApiError,
      enabled: false,
    },
    URL_CONF.AUTHENTICATION,
  );

  const handleVerifyEmail = () => {
    setLoading(true);
    mutate({});
  };

  return (
    <div className={classes.popStyle}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.modelStyle}
      >
        {loading && <Loader />}
        <Typography variant="h5">
          <span>
            <Close onClick={handleClose} />
          </span>
        </Typography>
        <img src={verifyEmail} alt="verify email logo" height="100%" width="100%" />
        <Typography variant="h2">Verify your email address</Typography>
        <Typography variant="subtitle2">Welcome to Virtual Pay</Typography>
        <Button variant="contained" endIcon={<TaskAlt />} onClick={handleVerifyEmail}>
          Verify Email
        </Button>
      </Dialog>
    </div>
  );
}

export default VerifyEmailPopup;
