import { Box, Grid, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useState } from 'react';
import useStyles from '../Auth.style';
import vpLogo from '../../../assets/vplogos.svg';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Personal from './Personal';
import { IS_MOBILE } from '../../../theme/constants';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Signup() {
  const classes = useStyles();
  const [valueTab, setValueTab] = useState(0);
  const userData = ['Personal', 'Business', 'Charity'];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const handleTittle = () => {
    const titleData = userData.filter((value, index) => index === valueTab).join('');
    return titleData;
  };

  return (
    <Grid item xs={12} sm={7.5}>
      <Box className={classes.rightMobileSection}>
        <Box className={classes.rightHeader}>
          <Box className={classes.rightHeaderMobileSection}>
            <Typography variant="h2">
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <a href="https://virtualpaysolutions.com/" aria-label="Go back">
                  <ChevronLeftIcon />
                </a>
                <span>Sign Up</span>
              </span>
              <span>
                <img src={vpLogo} alt="vpLogo" />
              </span>
            </Typography>
          </Box>
          <Typography variant="h1" className={classes.desktopHeadtext}>
            Create an account
          </Typography>
        </Box>
        <Box className={classes.signupAlign}>
          <Typography variant="h1" className={classes.mobileHeadText}>
            Create an account
          </Typography>
          <Box className={classes.tabHeaderStyle}>
            <Tabs
              value={valueTab}
              onChange={handleChange}
              aria-label="Tabs to select the signup option, ordered as Personal Business and Charity"
            >
              <Tab label="Personal" {...a11yProps(0)} />
              <Tab label="Business" {...a11yProps(1)} />
              <Tab label="Charity" {...a11yProps(2)} />
            </Tabs>
          </Box>
          {userData.map((value, index) => {
            if (index === valueTab) {
              return (
                <CustomTabPanel value={valueTab} index={IS_MOBILE ? 0 : valueTab} key={value}>
                  <Box className={`${classes.rightBody} ${classes.signUprightBody}`}>
                    <Personal tittleUserName={handleTittle()} userType={String(valueTab + 1)} />
                  </Box>
                </CustomTabPanel>
              );
            }
            return null;
          })}
        </Box>
      </Box>
    </Grid>
  );
}
export default Signup;
