import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useDashboardStyles = makeStyles((theme: Theme) => ({
  dashboardWrapper: {
    width: 'calc(100% - 181px)',
    marginLeft: '181px',
    padding: '20px 40px',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '15px',
    },
  },
  headerTittleStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& fieldset': {
      border: '1px solid rgba(198, 198, 198, 1) !important',
    },
    '& h2': {
      color: 'rgba(0, 0, 0, 1)',
    },
    '& label': {
      color: 'rgba(26, 76, 142, 1)',
      fontSize: '14px',
      marginRight: '20px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
    },
    '& .MuiTextField-root': {
      width: '267px',
    },
    '& input': {
      padding: '10.4px',
    },
  },
  datePickerStyle: {
    display: 'flex',
    alignItems: 'center',
    '& label': {
      marginRight: '-57px',
      marginBottom: '0px',
      marginTop: '18px',
    },
  },
  datePickerStyleInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& label': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '12px',
      margin: '0px 0px 3px 10px',
    },
    '& input': {
      fontSize: '12px',
      padding: '10.4px 0px 10.4px 10.4px',
    },
    '& svg': {
      fontSize: '18px',
    },
  },
  datePickerStyleStart: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    '& .MuiTextField-root': {
      width: 'auto',
    },
  },
  datePickerStyleStartBorderRight: {
    '& fieldset': {
      borderRight: 'none !important',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '0px',
    },
  },
  datePickerStyleStartBorderLeft: {
    '& fieldset': {
      borderLeft: 'none !important',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
    },
  },
  barChartPointer: {
    cursor: 'pointer',
  },
  numberStyles: {
    boxShadow: '0px 4px 11.100000381469727px 0px rgba(155, 155, 155, 0.25)',
    padding: '10px 15px 15px 15px',
    width: '20%',
    '& .recharts-wrapper': {
      width: '100% !important',
    },
    '&:nth-child(2)': {
      '& h1': {
        color: 'rgba(83, 177, 117, 1)',
      },
    },
    '&:nth-child(3)': {
      '& h1': {
        color: 'rgba(25, 104, 182, 1)',
      },
    },
    '&:nth-child(4)': {
      transition: '.6s',
      '& h1': {
        color: 'rgba(0, 82, 39, 1)',
      },
      '& g': {
        fontSize: '10px',
      },
      '&:hover': {
        backgroundColor: '#d3dde6',
      },
    },
    '& h4': {
      marginTop: '5px',
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '10px',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '10px',
      },
    },
    '& h1': {
      color: 'rgba(191, 168, 0, 1)',
      fontSize: '48px',
      lineHeight: '60px',
      marginBottom: '0px',
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '28px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '24px',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '24px',
      },
    },
  },
  numberStyleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
  },
  dashboardTabsStyle: {
    '& button': {
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 1)',
      textTransform: 'capitalize',
      fontFamily: 'Inter-Regular',
      padding: '15.5px 16px',
      marginRight: '20px',
      '&.Mui-selected': {
        backgroundColor: 'rgba(237, 244, 253, 1)',
      },
    },
  },
  tittleInputFilterStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    borderBottom: '1px solid rgba(211, 211, 211, 1)',
    '& input': {
      border: '1px solid rgba(50, 50, 50, 1)',
      borderRadius: '5px',
      padding: '9px 5px 9px 35px',
      fontSize: '14px',
      width: '149px',
    },
    '& button': {
      backgroundColor: 'transparent',
      border: '1px solid rgba(50, 50, 50, 1)',
      borderRadius: '5px',
      padding: '3.2px',
      marginLeft: '15px',
      '& svg': {
        verticalAlign: 'middle',
      },
    },
  },
  tittleInputFilterWrapperStyle: {
    boxShadow: '0px 4px 13px 0px rgba(143, 143, 143, 0.25)',
    marginTop: '25px',
  },
  searchIconWrapperStyle: {
    position: 'relative',
  },
  searchIconnStyle: {
    '& svg': {
      position: 'absolute',
      left: '12px',
      fontSize: '18px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  chartPoupStyle: {
    '& .MuiDialogContent-root': {
      padding: ' 20px 30px',
    },
    '& .MuiPaper-root': {
      maxWidth: '760px',
    },
    '& h2': {
      color: '#000',
    },
    '& h6': {
      color: '#515151',
      textAlign: 'center',
      marginTop: '10px',
    },
  },
  tittleCorssStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '40px',
    '& svg': {
      color: '#000',
      cursor: 'pointer',
    },
  },
  datePickerIconStyle: {
    '& svg': {
      marginRight: '20px',
    },
  },
}));

export default useDashboardStyles;
