import { Box, Grid, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import back from '../../../assets/back_Color.svg';
import useStyles from '../Auth.style';

function InvalidRequest() {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6}>
      <Box className={classes.rightMobileSection}>
        <Box className={`${classes.rightHeader} ${classes.rightHeaderForgot}`}>
          <Box className={classes.desktopHeadtext}>
            <Typography variant="h1" style={{ color: '#cb0303', fontSize: '20px' }}>
              Invalid Request!
            </Typography>
            <Typography variant="body1">
              Reset Password Link has been expired
              <br />
              cannot reset your password.
            </Typography>
          </Box>
        </Box>

        <Box className={classes.rightBody} style={{ textAlign: 'center' }}>
          <Box className={classes.mobileHeadText}>
            <Typography variant="h1" style={{ color: '#cb0303', fontSize: '20px' }}>
              Invalid Request!
            </Typography>
            <Typography variant="body1" className={classes.mobileSmText}>
              Reset Password Link has been expired cannot reset your password.
            </Typography>
          </Box>
          <Box className={classes.backReturnButton}>
            <Typography variant="body1">
              <NavLink to="/login">
                <span>
                  <img src={back} alt="back" />
                </span>
                Return to login
              </NavLink>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
export default InvalidRequest;
