import { GraphQLClient } from 'graphql-request';
import { getAccessTokenFromLocalStorage } from './storageHelper';
import { GRAPHQL, SERVER_URL } from './constants';

export interface HeadersProps {
  headers: {
    authorization?: string;
  };
}
const gqlConfig = (path: string, tokenData?: string) => {
  const token = getAccessTokenFromLocalStorage() || tokenData;
  const endpoint = `${SERVER_URL}${GRAPHQL}${path}`;
  const headers: HeadersProps = {
    headers: {},
  };
  if (token) {
    headers.headers.authorization = `Bearer ${token}`;
  }
  const graphQLClient = new GraphQLClient(endpoint, headers);
  return graphQLClient;
};

export default gqlConfig;
