import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { NavLink, useNavigate } from 'react-router-dom';
// import apple from '../../../assets/apple.svg';
import vpLogo from '../../../assets/vplogos.svg';
import Loader from '../../../components/loader/index';
import {
  ERRORS,
  MESSAGES,
  KEY_CONF,
  USER_VERIFIED_STATUS,
  URL_CONF,
  ADMIN_ROLE,
} from '../../../helper/constants';
import { setEncodedItemDataToLocalStorage } from '../../../helper/storageHelper';
import { capitalize, emailValidator, passwordValidator } from '../../../helper/validators';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import { ILoginErrorState, ILoginFormState } from '../../../interfaces/Auth';
import userQueries from '../../../queries/user';
import { PATH_ADMINPANEL, PATH_ONBOARDING, PATH_OTC } from '../../../routers/path';
import { QueryErrorResponse } from '../../../types/UserDetailType';
import useStyles from '../Auth.style';
import AccountType from '../components/accounttypepopup';
import VerifyEmailPopup from '../verifyemailpopup';
import { useDispatch } from 'react-redux';
import { addUserData } from '../../../redux/reducer/loginUser';
import { useHandleLogin } from '../../../hooks/useLogin';
import GoogleSSOAuth from '../googlesso';

function Login() {
  // const notify = () => toast('Here is your toast.');
  const classes = useStyles();
  const navigate = useNavigate();
  const handleLogin = useHandleLogin();
  const [loader] = useState(false);
  const [token, setToken] = useState<string>('');
  // To open popup for Email Verification during Sign up
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);
  // to open popup for Account type during Google SSO
  const [showAccountTypePopup, setShowAccountTypePopup] = useState(false);
  // contains data for the user like userId to recognize the user
  const [userId, setUserIdData] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState<ILoginFormState>({
    email: '',
    password: '',
  });
  const [errorState, setErrorState] = useState<ILoginErrorState>({
    email: '',
    password: '',
  });
  const dispatch = useDispatch();

  // Handling the email verification popup opening
  useEffect(() => {
    // Check if the URL contains a token for email verification
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get('token');
    if (accessToken) {
      setToken(accessToken);
      // Token exists, show the verification popup
      setShowVerificationPopup(true);
    }
  }, []);

  const validateFields = (name: string, value: string) => {
    if (value.trim().length === 0) {
      setErrorState((prevState) => ({
        ...prevState,
        [name]: `${capitalize(name)} is required`,
      }));
      return;
    }
    switch (name) {
      case 'email':
        setErrorState((prevState) => ({
          ...prevState,
          email: !emailValidator(value) ? ERRORS.EMAIL_ERROR : null,
        }));
        break;
      case 'password':
        setErrorState((prevState) => ({
          ...prevState,
          password: !passwordValidator(value) ? ERRORS.PASSWORD_ERROR : null,
        }));
        break;
      default:
        break;
    }
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value.trim().length === 0) {
      setErrorState((prevState) => ({
        ...prevState,
        [name]: `${capitalize(name)} is required`,
      }));
    } else {
      validateFields(name, value);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  /**
   * Function to validate data
   */
  const validateUserData = () => {
    const { email, password } = state;
    if (emailValidator(email) && passwordValidator(password)) {
      return true;
    }
    validateFields('email', email);
    validateFields('password', email);
    return false;
  };

  // Error handling---------------------------------------------
  const handleApiError = ({ response }: QueryErrorResponse) => {
    const message =
      response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
    if (message) {
      toast.error(message);
    }
    return true;
  };

  // handling user login--------------------------------------
  const isCall = false;
  const {
    data: userData,
    isFetching,
    refetch,
    isError,
    error,
  } = useGQLQuery(
    [KEY_CONF.USERS],
    userQueries.USER_LOGIN(state.email.toLowerCase(), state.password),
    {},
    {
      enabled: isCall,
    },
    URL_CONF.AUTHENTICATION,
  );

  useEffect(() => {
    if (isError && error !== null) {
      handleApiError(error);
    }
  }, [error, isError]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateUserData()) {
      refetch();
    }
  };

  // user data handling--------------------------------------------
  useEffect(() => {
    if (userData && userData.login) {
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        info,
        email,
      } = userData.login;
      handleLogin(accessToken, refreshToken);
      setEncodedItemDataToLocalStorage('userData', JSON.stringify({ ...info, email }));
      toast.success(MESSAGES.USER_LOGIN);
      if (accessToken) {
        // If user's pending_stage is verified, then go to dashboard, else onboarding
        if (info.role === ADMIN_ROLE) {
          navigate(PATH_ADMINPANEL.children.dashboard);
        } else {
          navigate(
            info.pending_stage === USER_VERIFIED_STATUS ? PATH_OTC.dashboard : PATH_ONBOARDING.root,
          );
        }
      }
      // Add user data in redux store
      dispatch(addUserData({ ...info, email }));
    }
  }, [userData, dispatch, handleLogin, navigate]);
  //-----------------------------------------------------------------------

  return (
    <>
      {(isFetching || loader) && <Loader />}
      <Grid item xs={12} sm={6}>
        {showVerificationPopup && (
          <VerifyEmailPopup
            open={showVerificationPopup}
            handleClose={() => setShowVerificationPopup(false)}
            token={token}
          />
        )}
        {showAccountTypePopup && (
          <AccountType
            open={showAccountTypePopup}
            handleClose={() => setShowAccountTypePopup(false)}
            userId={userId}
          />
        )}
        <Box className={classes.rightMobileSection}>
          <Box className={classes.rightHeader}>
            <Box className={classes.rightHeaderMobileSection}>
              <Typography variant="h2">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <a href="https://virtualpaysolutions.com/" aria-label="Go back">
                    <ChevronLeftIcon />
                  </a>
                  <span>Login</span>
                </span>
                <span>
                  <img src={vpLogo} alt="vpLogo" />
                </span>
              </Typography>
            </Box>
            <Typography variant="h1" className={classes.desktopHeadtext}>
              Hi, Welcome Back! 👋
            </Typography>
          </Box>

          <Box className={classes.rightBody}>
            <Typography variant="h1" className={classes.mobileHeadText}>
              Hi, Welcome Back! 👋
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box className={classes.rightBodyInput}>
                <InputLabel>Email *</InputLabel>
                <TextField
                  id="outlined-basic-email"
                  type="email"
                  value={state.email}
                  autoComplete="off"
                  name="email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChangeName(e)}
                  placeholder="Please Enter Your Email"
                  variant="outlined"
                  className={state.email.length > 0 ? classes.borderStyle : ''}
                />
                <span className={classes.errorStyle}>{errorState.email}</span>
              </Box>
              <Box className={classes.rightBodyInput}>
                <InputLabel>Password *</InputLabel>
                <TextField
                  id="outlined-basic"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={state.password}
                  placeholder="Please Enter Your Password"
                  variant="outlined"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChangeName(e)}
                  className={state.password.length > 0 ? classes.borderStyle : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleTogglePasswordVisibility}
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <span className={classes.errorStyle}>{errorState.password}</span>
                <Typography variant="body1">
                  <NavLink to="/forgot-password">Forgot Password?</NavLink>
                </Typography>
              </Box>
              <Box className={classes.rightBodyButton}>
                <button type="submit">Login</button>
              </Box>
              <Box className={classes.rightBodyOrWith}>
                <Typography variant="body1">
                  <span>Or With</span>
                </Typography>
              </Box>
              <Box className={classes.authImg}>
                <GoogleSSOAuth
                  setUserIdData={setUserIdData}
                  setShowAccountTypePopup={setShowAccountTypePopup}
                />
                {/* <img src={apple} alt="apple" /> */}
              </Box>
              <Box className={classes.authContent}>
                <Typography variant="body1">
                  Don’t have an account ?
                  <span>
                    <NavLink to="/signup">Sign Up</NavLink>
                  </span>
                </Typography>
              </Box>
            </form>
          </Box>
        </Box>
      </Grid>
    </>
  );
}
export default Login;
