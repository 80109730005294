import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  mainDilogBoxSection: {
    '& .MuiPaper-root': {
      borderRadius: '10px !important',
      minWidth: '25%',
      width: '25%',
      [theme.breakpoints.between('sm', 'md')]: {
        minWidth: '80%',
        width: '80%',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        minWidth: '98%',
        width: '98%',
      },
    },
  },
  blueHead: {
    color: '#1A4C8E',
    fontFamily: 'Inter-Regular !important',
    paddingBottom: '4px',
    paddingTop: '4px',
  },
  dilogBoxNo: {
    padding: '38px 24px 0px 24px !important',
    color: '#000000',
  },
  dialogBody: {
    padding: '24px !important',
    '& input': {
      padding: '6px',
      height: '27px',
      fontSize: '15px',
      borderRadius: '5px',
      border: '1px solid #C6C6C6',
      backgroundColor: '#ffffff',
      color: '#6A6A6A',
      fontFamily: 'Inter-Medium',
    },
    '& .Mui-disabled': {
      backgroundColor: '#E0E0E0',
    },
  },
  sectionBody: {
    '& .MuiFormControl-root': {
      margin: '0px !important',
      minWidth: '100%',
    },
    '& .MuiSelect-select': {
      padding: '6px',
      fontSize: '15px',
      height: '27px !important',
      borderRadius: '5px',
      border: '1px solid #C6C6C6',
      backgroundColor: '#ffffff',
      color: '#6A6A6A',
      alignItems: 'center',
      display: 'flex',
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '16px',
      },
    },
  },
  dilogBoxSectionFooter: {
    padding: '24px !important',
    justifyContent: 'space-between !important',
  },
  footerBtnFillBlue: {
    '& button': {
      color: '#fff',
      backgroundColor: '#1968B6',
      borderRadius: '5px',
      cursor: 'pointer',
      textAlign: 'center',
      textTransform: 'capitalize',
      padding: '4px 0px',
      border: '2px solid #1968B6',
      width: '145px',
      fontSize: 17,
      fontFamily: 'Inter-Regular',
      '&:hover': {
        backgroundColor: '#1968B6',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: 12,
        width: '100px',
      },
    },
  },
  footerBtnFillGrey: {
    '& button': {
      color: '#565656',
      backgroundColor: '#E6E6E6',
      borderRadius: '5px',
      cursor: 'pointer',
      textAlign: 'center',
      textTransform: 'capitalize',
      padding: '4px 0px',
      border: '2px solid #E6E6E6',
      width: '100px',
      fontSize: 17,
      fontFamily: 'Inter-Regular',
      '&:hover': {
        backgroundColor: '#E6E6E6',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: 12,
        width: '100px',
      },
    },
  },
  rightSideBtn: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
  },
  borderStyleNo: {
    '& fieldset': {
      border: '1px solid rgba(198, 198, 198, 1) !important',
    },
  },
}));
