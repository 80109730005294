import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  accessToken: '',
};

export const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAccessToken } = userAuthSlice.actions;

export default userAuthSlice.reducer;
