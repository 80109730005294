import { Box } from '@mui/material';
import { ColorRing } from 'react-loader-spinner';
import useLoaderStyles from './Loader.style';

function Loader() {
  const classes = useLoaderStyles();
  return (
    <Box className={classes.loaderStyle}>
      {/* <Hourglass
                visible
                height="80"
                width="80"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#306cce', '#72a1ed']}
            /> */}
      <ColorRing
        visible
        height="120"
        width="120"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#1769B6', '#A5DCFC', '#0A2A5C', '#A5DCFC', '#0A2A5C']}
      />
    </Box>
  );
}
export default Loader;
