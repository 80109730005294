import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import banner from '../../../../assets/banner.png';

const useLeftBannerStyles = makeStyles((theme: Theme) => ({
  leftBanner: {
    backgroundImage: `url(${banner})`,
    width: '37.5%',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'fixed',
    [theme.breakpoints.between(767, 1200)]: {
      height: '100%',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'none',
    },
  },
  loginPageLeftBanner: {
    width: '50%',
    [theme.breakpoints.between(767, 1200)]: {
      height: '100vh',
    },
  },
  innerLeftBanner: {
    padding: '20px 0px 0px 40px',
    '& h1': {
      color: '#fff',
      fontFamily: 'Khand-SemiBold',
      fontSize: '69px',
      lineHeight: '100px',
      paddingTop: '50px',
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '50px',
        lineHeight: '70px',
      },
    },
  },
  bottomSetion: {
    color: '#fff',
    position: 'absolute',
    bottom: '5%',
    '& h2': {
      cursor: 'pointer',
    },
    '& span': {
      verticalAlign: 'text-top',
      marginRight: '10px',
    },
  },
}));

export default useLeftBannerStyles;
