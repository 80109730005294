import { useDispatch } from 'react-redux';
import { clearAccessTokenOnLogout } from '../helper/storageHelper';
import { setAccessToken } from '../redux/reducer/userAuth';
import { resetStep } from '../redux/reducer/useronboarding';
import { useGQLMutation } from './useGQLMutation';
import userQueries from '../queries/user';
import { URL_CONF } from '../helper/constants';
import { QueryErrorResponse } from '../types/UserDetailType';
import toast from 'react-hot-toast';

export const useLogout = () => {
  const dispatch = useDispatch();
  // Error handling---------------------------------------------
  const handleApiError = ({ response }: QueryErrorResponse) => {
    const message =
      response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
    if (message) {
      toast.error(message);
    }
  };

  const { mutate } = useGQLMutation(
    'logout',
    userQueries.USER_LOGOUT(),
    {
      onError: handleApiError,
      enabled: false,
    },
    URL_CONF.LOGOUT,
  );

  function logout() {
    mutate({}); // Call the logout mutation
    clearAccessTokenOnLogout();
    dispatch(setAccessToken(''));
    dispatch(resetStep());
  }
  return logout;
};
