import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validators';

const GET_USERS_INFO = (skip: number, take: number, setUser: boolean, searchText: string) => {
  const searchParams = searchText ? addEscapeCharacters(searchText) : '""';
  return gql`
    query {
      adminAllUsers(skip:${skip}, take:${take}, filter: { is_active: ${setUser}, search_keyword: ${searchParams} }) {
        pageInfo {
          skip
          take
          has_next_page
          total_count
        }
        data {
          email
          id
          contact
          full_name
          plaid_verified
          pending_stage
          is_active
          email_verified
          self_referral_code
        }
      }
    }
`;
};

export default {
  GET_USERS_INFO,
};
