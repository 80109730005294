export const emailValidator = (email: string) => {
  const regExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return regExp.test(email);
};

export const passwordValidator = (password: string) => {
  const regExp = /^(?=^.{8,32}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  return regExp.test(password);
};

export const userNameValidator = (user: string) => {
  if (user) {
    return true;
  }
  return false;
};

function addEscapeCharacters(str: string | null) {
  return str ? `"${str.trim()}"` : '';
}

export const phoneNumberValidator = (user: string) => {
  const regExp = /^\d+$/;
  const result = regExp.test(user);
  return result;
};

export const capitalize = (word: string) =>
  word
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (char) => char.toUpperCase())
    .trim();

export const dateOfBirthValidator = (user: string) => {
  const dateOfBirthRegex = /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01])$/;
  return dateOfBirthRegex.test(user);
};

export const accBankingAddress = (user: string) => {
  const addressRegex = /^[a-zA-Z0-9\s.,#-]+$/;
  return addressRegex.test(user);
};

export const accValidNumber = (user: string) => {
  const regExp = /^\d+$/;
  const result = regExp.test(user);
  return result;
};
export const swiftBIC = (user: string) => {
  const regExp = /^[a-zA-Z0-9]+$/;
  const result = regExp.test(user);
  return result;
};

export const accInstutionNumber = (user: string) => {
  const regExp = /^\d+$/;
  const result = regExp.test(user);
  return result;
};
// trade order
export const tradeOrderAmount = (user: string) => {
  const numberRegex = /^-?\d*\.?\d+$/;
  return numberRegex.test(user);
};
export const tradeOrderValidator = (user: string) => {
  const regExp = /^[a-zA-Z ]{2,30}$/;
  return regExp.test(user);
};

export const walletAddressValid = (user: string) => {
  const regExp = /\b(?:[0-9]+[a-zA-Z]|[a-zA-Z]+[0-9])[0-9a-zA-Z]*\b/g;
  return regExp.test(user);
};

export const numberValidator = (num: number) => Number.isNaN(+num);

export const referralCodeValidator = (code: string) => {
  const regExp = /^[a-zA-Z0-9]*$/;
  return regExp.test(code);
};

export { addEscapeCharacters };
