import React, { useEffect } from 'react';
import { Box, Typography, Grid, TextField } from '@mui/material';

import PhoneInput from 'react-phone-input-2';
import { RootState } from '../../../../redux/store';
import BusinessInformationIcon from '../../../../assets/business_center.svg';
import LegalBusinessIcon from '../../../../assets/store_mall_directory.svg';
import RepresentativeIcon from '../../../../assets/perm_identity.svg';
import BusinessStyle from './BusinessInformation.style';
import { useSelector, useDispatch } from 'react-redux';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import userQueries from '../../../../queries/formData';
import { getDecodedItemDataFromLocalStorage, JSONParse } from '../../../../helper/storageHelper';
import { ISingnUpInfo } from '../../../../interfaces/Auth';
import { AccountType, KEY_CONF } from '../../../../helper/constants';
import { dobFormatter } from '../../../../helper/format';
import { QueryErrorResponse } from '../../../../types/UserDetailType';
import { setDraftData } from '../../../../redux/reducer/useronboarding';
import toast from 'react-hot-toast';

function BusinessInformation() {
  const classes = BusinessStyle();
  const saveAsDraftData = useSelector(
    (state: RootState) => state.userOnboardingStep.saveAsDraftData,
  );
  const userDataString = getDecodedItemDataFromLocalStorage('userData');
  const userData = userDataString ? JSONParse<ISingnUpInfo>(userDataString) : null;
  const accountType: AccountType | undefined = Number(userData?.user_type);
  const userType = accountType ? AccountType[accountType] : 'Business';
  const basicInformation = saveAsDraftData?.basicInfoData;
  const userAddress = saveAsDraftData?.basicInfoData?.address;
  const dateOfBirthString = basicInformation?.user_info?.dob;
  const formattedDateOfBirth =
    dateOfBirthString && dobFormatter({ dateString: dateOfBirthString || '' });
  const loggedInUserData = useSelector((state: RootState) => state.userData.value);

  const dispatch = useDispatch();

  // Error handling---------------------------------------------
  const handleApiError = ({ response }: QueryErrorResponse) => {
    const message =
      response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
    if (message) {
      toast.error(message);
    }
    return true;
  };

  const { data: userDraftData } = useGQLQuery(
    [KEY_CONF.GET_DRAFT_DATA],
    userQueries.OTC_ACCOUNT_DATA(),
    {},
    {
      onError: handleApiError,
    },
  );

  useEffect(() => {
    if (userDraftData?.get_otc_account_data) {
      const address = userDraftData?.get_otc_account_data?.basicInfoData?.address;
      const userInfo = userDraftData?.get_otc_account_data?.basicInfoData?.user_info;
      const accountData = userDraftData?.get_otc_account_data?.accountData || [];

      const draftData = {
        accountData: [
          {
            currency: accountData[0]?.currency || '',
            accountDescription: accountData[0]?.account_description || '',
            bankingInstruction: accountData[0]?.banking_instructions || '',
            bankingAddress: accountData[0]?.bank_address || '',
            institutionNumber: accountData[0]?.institute_number || '',
            transitNumber: accountData[0]?.transit_number || '',
            accountNumber: accountData[0]?.account_number || '',
            swiftBic: accountData[0]?.swift_bic || '',
            accountHolderFirstName: accountData[0]?.account_holder_first_name || '',
            accountHolderLastName: accountData[0]?.account_holder_last_name || '',
            accountHolderEmail: accountData[0]?.account_holder_email || '',
            beneficiary: accountData[0]?.beneficiary_name || '',
            beneficiaryAddress: accountData[0]?.beneficiary_address || '',
            isManuallyCreated: accountData[0]?.is_manually_created || false,
            eft_institution: accountData[0]?.eft_institution || '',
            eft_branch: accountData[0]?.eft_branch || '',
            bacs_sort_code: accountData[0]?.bacs_sort_code || '',
            international_bic: accountData[0]?.international_bic || '',
            international_iban: accountData[0]?.international_iban || '',
          },
        ],
        basicInfoData: {
          address: {
            addressLineOne: address?.address_line_1 || '',
            addressLineTwo: address?.address_line_2 || '',
            country: address?.country || '',
            city: address?.city || '',
            state: address?.state || '',
            postal_code: address?.postal_code || '',
          },
          user_info: {
            firstName: userInfo?.first_name || '',
            lastName: userInfo?.last_name || '',
            contact:
              userInfo?.contact || userDraftData?.get_otc_account_data?.googleSSOUserContact || '',
            email: userInfo?.email || '',
            settlementCurrency: userInfo?.settlement_currency || '',
            dob: userInfo?.dob || '',
            businessType: userInfo?.business_type || '',
            industry: userInfo?.business_industry || '',
            businessName: userInfo?.org_name || '',
            charityName: userInfo?.org_name || '',
            website: userInfo?.website || '',
            businessTaxID: userInfo?.tax_id || '',
            charityTaxID: userInfo?.tax_id || '',
            representativeContact: userInfo?.representative_contact,
            representativeContactCountryCode: userInfo?.representative_contact_country_code,
            representativeEmail: userInfo?.representative_email,
          },
        },
      };
      dispatch(setDraftData(draftData));
    }
  }, [userDraftData, dispatch]);

  const isBusinessOrCharity = accountType !== 1;
  const representativeContact = `+
    ${basicInformation?.user_info?.representativeContactCountryCode}
    ${basicInformation?.user_info?.representativeContact}`;
  const loggedInUserDataContact = `+${loggedInUserData?.contact_country_code}${loggedInUserData?.contact}`;
  return (
    <Box className={classes.mainSection}>
      {(userType === 'Business' || userType === 'Charity') && (
        <Grid container spacing={4} className={classes.sectionBody}>
          <Grid item xs={12}>
            <Typography className={classes.subHeaderText} variant="h2">
              <img src={BusinessInformationIcon} alt={BusinessInformationIcon} />
              {userType} Information
            </Typography>
          </Grid>
          {userType === 'Business' && (
            <Grid item md={4.5} sm={6} xs={12}>
              <Typography variant="body1" className={classes.labelText}>
                Business Type
              </Typography>
              <TextField
                fullWidth
                color="secondary"
                value={basicInformation?.user_info?.businessType}
                disabled
              />
            </Grid>
          )}
          <Grid item md={4.5} sm={6} xs={12}>
            <Typography variant="body1" className={classes.labelText}>
              Industry
            </Typography>
            <TextField
              fullWidth
              color="secondary"
              value={basicInformation?.user_info?.industry}
              disabled
            />
          </Grid>
          <Grid item md={4.5} sm={6} xs={12}>
            <Typography variant="body1" className={classes.labelText}>
              {userType} Name
            </Typography>
            <TextField
              fullWidth
              color="secondary"
              value={
                basicInformation?.user_info?.businessName ||
                basicInformation?.user_info?.charityName
              }
              disabled
            />
          </Grid>
          <Grid item md={4.5} sm={6} xs={12}>
            <Typography variant="body1" className={classes.labelText}>
              Website
            </Typography>
            <TextField
              fullWidth
              color="secondary"
              value={basicInformation?.user_info?.website}
              disabled
            />
          </Grid>
          <Grid item md={4.5} sm={6} xs={12}>
            <Typography variant="body1" className={classes.labelText}>
              Settlement Currency
            </Typography>
            <TextField
              fullWidth
              color="secondary"
              value={basicInformation?.user_info?.settlementCurrency}
              disabled
            />
          </Grid>
          <Grid item md={4.5} sm={6} xs={12}>
            <Typography variant="body1" className={classes.labelText}>
              {userType} Tax ID
            </Typography>
            <TextField
              fullWidth
              color="secondary"
              value={
                basicInformation?.user_info?.businessTaxID ||
                basicInformation?.user_info?.charityTaxID
              }
              disabled
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4} className={classes.sectionBody}>
        <Grid item xs={12}>
          <Typography className={classes.mt40} variant="h2">
            <img src={LegalBusinessIcon} alt={LegalBusinessIcon} />
            Legal {userType} Address
          </Typography>
        </Grid>
        <Grid item md={9} sm={12} xs={12}>
          <Typography variant="body1" className={classes.labelText}>
            Country
          </Typography>
          <TextField fullWidth color="secondary" value={userAddress?.country} disabled />
        </Grid>
        <Grid item md={4.5} sm={6} xs={12}>
          <Typography variant="body1" className={classes.labelText}>
            Address Line 1
          </Typography>
          <TextField fullWidth color="secondary" value={userAddress?.addressLineOne} disabled />
        </Grid>
        <Grid item md={4.5} sm={6} xs={12}>
          <Typography variant="body1" className={classes.labelText}>
            Address Line 2
          </Typography>
          <TextField fullWidth color="secondary" value={userAddress?.addressLineTwo} disabled />
        </Grid>
        <Grid item md={4.5} sm={6} xs={12}>
          <Typography variant="body1" className={classes.labelText}>
            City
          </Typography>
          <TextField fullWidth color="secondary" value={userAddress?.city} disabled />
        </Grid>
        <Grid item md={4.5} sm={6} xs={12}>
          <Typography variant="body1" className={classes.labelText}>
            Province/State
          </Typography>
          <TextField fullWidth color="secondary" value={userAddress?.state} disabled />
        </Grid>
        <Grid item md={4.5} sm={6} xs={12}>
          <Typography variant="body1" className={classes.labelText}>
            Postal Code
          </Typography>
          <TextField fullWidth color="secondary" value={userAddress?.postal_code} disabled />
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.sectionBody}>
        <Grid item xs={12}>
          <Typography className={classes.mt40} variant="h2">
            <img src={RepresentativeIcon} alt={RepresentativeIcon} />
            Representative Information
          </Typography>
        </Grid>
        <Grid item md={4.5} sm={6} xs={12}>
          <Typography variant="body1" className={classes.labelText}>
            First Name*
          </Typography>
          <TextField
            fullWidth
            color="secondary"
            value={basicInformation?.user_info?.firstName}
            disabled
          />
        </Grid>
        <Grid item md={4.5} sm={6} xs={12}>
          <Typography variant="body1" className={classes.labelText}>
            Last Name*
          </Typography>
          <TextField
            fullWidth
            color="secondary"
            value={basicInformation?.user_info?.lastName}
            disabled
          />
        </Grid>
        <Grid item md={4.5} sm={6} xs={12}>
          <Typography variant="body1" className={classes.labelText}>
            Email*
          </Typography>
          <TextField
            fullWidth
            color="secondary"
            value={
              isBusinessOrCharity
                ? basicInformation?.user_info?.representativeEmail
                : loggedInUserData?.email
            }
            disabled
          />
        </Grid>
        <Grid item md={4.5} sm={6} xs={12}>
          <Typography variant="body1" className={classes.labelText}>
            Primary Phone Number*
          </Typography>
          <Box className={`${classes.rightBodyInput} ${classes.telephoneBoxStyle}`}>
            <PhoneInput
              country="us"
              value={isBusinessOrCharity ? representativeContact : loggedInUserDataContact}
              disabled
            />
          </Box>
        </Grid>
        <Grid item md={4.5} sm={6} xs={12}>
          <Typography variant="body1" className={classes.labelText}>
            Date of Birth*
          </Typography>
          <TextField fullWidth color="secondary" value={formattedDateOfBirth} disabled />
        </Grid>
      </Grid>
    </Box>
  );
}

export default BusinessInformation;
