import React from 'react';
import ProfileStyle from './Profile.style';
import BusinessInformation from './component/businessinformation';
import AccountInformation from './component/accountinformation';
import AccountPayout from './component/accountpayout';
import VerticalTabsWithSections from './tabs';
import { Box } from '@mui/material';
import { getDecodedItemDataFromLocalStorage, JSONParse } from '../../helper/storageHelper';
import { ISingnUpInfo } from '../../interfaces/Auth';
import { AccountType } from '../../helper/constants';

function Profile() {
  const classes = ProfileStyle();
  const userDataString = getDecodedItemDataFromLocalStorage('userData');
  const userData = userDataString ? JSONParse<ISingnUpInfo>(userDataString) : null;
  const accountType: AccountType | undefined = Number(userData?.user_type);
  const userType = accountType ? AccountType[accountType] : 'Business';
  const tabs = [
    { id: 1, value: 'Account Information' },
    { id: 2, value: `${userType} Information` },
    { id: 3, value: 'Account Info for Payout' },
  ];
  const sections = [
    {
      id: 1,
      value: <AccountInformation />,
    },
    {
      id: 2,
      value: <BusinessInformation />,
    },
    {
      id: 3,
      value: <AccountPayout />,
    },
  ];
  return (
    <div>
      <Box className={classes.mainContainer}>
        <VerticalTabsWithSections tabs={tabs} sections={sections} />
      </Box>
    </div>
  );
}

export default Profile;
