import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import vpLogo from '../../../assets/vplogos.svg';
import Loader from '../../../components/loader';
import SuccessPopup from '../../../components/successpopup';
import { ERRORS, URL_CONF } from '../../../helper/constants';
import { capitalize, passwordValidator } from '../../../helper/validators';
import { useGQLMutation } from '../../../hooks/useGQLMutation';
import { IResetPasswordError, IResetPasswordState } from '../../../interfaces/Auth';
import userQueries from '../../../queries/user';
import { QueryErrorResponse } from '../../../types/UserDetailType';
import useStyles from '../Auth.style';
import { PATH_AUTH } from '../../../routers/path';
import { isTokenExpired } from '../../../helper/storageHelper';
import { IS_MOBILE } from '../../../theme/constants';

function ResetPassword() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [showPasswordResetPopup, setPasswordResetPopup] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [passwordsMatchError, setPasswordsMatchError] = useState<string>('');
  const [errorState, setErrorState] = useState<IResetPasswordError>({
    password: '',
    confirmPassword: '',
  });
  const [passwordState, setPasswordState] = useState<IResetPasswordState>({
    password: '',
    confirmPassword: '',
  });
  const PAGE_TITLE = IS_MOBILE ? 'Authentication' : 'Reset Password';

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get('token');

    // Check if the token is expired
    if (tokenFromUrl) {
      const isExpired = isTokenExpired(tokenFromUrl);
      if (isExpired) {
        navigate('/invalid-request');
      } else {
        setToken(tokenFromUrl);
      }
    }
  }, [location, navigate]);

  const validateFields = (name: string, value: string) => {
    if (value.trim().length === 0) {
      setErrorState((prevState) => ({
        ...prevState,
        [name]: `${capitalize(name)} is required`,
      }));
      return;
    }
    switch (name) {
      case 'password':
        setErrorState((prevState) => ({
          ...prevState,
          password: !passwordValidator(value) ? ERRORS.PASSWORD_ERROR : ' ',
        }));
        break;
      case 'confirmPassword':
        setErrorState((prevState) => ({
          ...prevState,
          confirmPassword: !passwordValidator(value) ? ERRORS.PASSWORD_ERROR : ' ',
        }));
        break;
      default:
        break;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value.trim().length === 0) {
      setErrorState((prevState) => ({
        ...prevState,
        [name]: `${capitalize(name)} is required`,
      }));
    } else {
      validateFields(name, value);
    }
  };

  /**
   * Function to validate password
   */
  const validateUserPassword = () => {
    const { password, confirmPassword } = passwordState;
    if (passwordValidator(password) && passwordValidator(confirmPassword)) {
      // Check if passwords match
      if (password === confirmPassword) {
        setPasswordsMatchError('');
        return true;
      }
      setPasswordsMatchError('New password and confirm password do not match');
      return false;
    }
    validateFields('password', password);
    validateFields('confirmPassword', confirmPassword);
    return false;
  };

  // Error handling---------------------------------------------
  const handleApiError = ({ response }: QueryErrorResponse) => {
    const message =
      response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
    if (message) {
      toast.error(message);
    }
    setLoading(false);
  };

  // handling reset password mutation using GQL
  const { mutate } = useGQLMutation(
    'resetPassword',
    userQueries.RESET_PASSWORD(passwordState.password, passwordState.confirmPassword, token),
    {
      onSuccess: () => {
        setLoading(false);
        setPasswordResetPopup(true);
      },
      onError: handleApiError,
      enabled: false,
    },
    URL_CONF.AUTHENTICATION,
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateUserPassword()) {
      setLoading(true);
      mutate({});
    }
  };

  const handleTogglePasswordVisibility = (field: string) => {
    if (field === 'password') {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    }
  };

  const handleClose = () => {
    setPasswordResetPopup(false);
    navigate(PATH_AUTH.children.login);
  };

  return (
    <Grid item xs={12} sm={6}>
      {loading && <Loader />}
      {showPasswordResetPopup && (
        <SuccessPopup
          open={showPasswordResetPopup}
          handleClose={handleClose}
          title="You have Successfully Reset Your Password!"
          subtitle="Go back to login with Your New Password"
        />
      )}
      <Box className={classes.rightMobileSection}>
        <Box className={`${classes.rightHeader} ${classes.rightHeaderChange}`}>
          <Box className={classes.rightHeadWraper}>
            <Box className={classes.rightHeaderMobileSection}>
              <Typography variant="h2">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <a href="https://virtualpaysolutions.com/" aria-label="Go back">
                    <ChevronLeftIcon />
                  </a>
                  <span>Reset Password</span>
                </span>
                <span>
                  <img src={vpLogo} alt="vpLogo" />
                </span>
              </Typography>
            </Box>
            <Box className={classes.desktopHeadtext}>
              <Typography variant="h1">{PAGE_TITLE}</Typography>
              <Typography variant="body1">
                Reset your password here and get back to Login
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={`${classes.rightBody} ${classes.rightBodyChange}`}>
          <Box className={classes.mobileHeadText}>
            <Typography variant="h1">{PAGE_TITLE}</Typography>
            <Typography variant="body1" className={classes.mobileSmText}>
              Reset your password here and get back to Login
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box className={classes.rightBodyInput}>
              <InputLabel>New Password*</InputLabel>
              <TextField
                id="outlined-basic"
                value={passwordState.password}
                type={showPassword ? 'text' : 'password'}
                name="password"
                autoComplete="off"
                placeholder="New Password"
                variant="outlined"
                className={classes.borderStyle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChange(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => handleTogglePasswordVisibility('password')}
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <span className={classes.errorStyle}>{errorState.password}</span>
            </Box>
            <Box className={classes.rightBodyInput}>
              <InputLabel>Confirm Password*</InputLabel>
              <TextField
                id="outlined-basic"
                value={passwordState.confirmPassword}
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                autoComplete="off"
                placeholder="Confirm Password"
                variant="outlined"
                className={classes.borderStyle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChange(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => handleTogglePasswordVisibility('confirmPassword')}
                        aria-label="toggle password visibility"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <span className={classes.errorStyle}>{errorState.confirmPassword}</span>
              <span className={classes.errorStyle}>{passwordsMatchError}</span>
            </Box>
            <Box className={classes.rightBodyButton}>
              <button type="submit">Reset Password</button>
            </Box>
          </form>
        </Box>
      </Box>
    </Grid>
  );
}

export default ResetPassword;
