import React from 'react';
import popupStyle from './SuccessPopup';
import Dialog from '@mui/material/Dialog';
import right from '../../assets/right.svg';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

type PopupProps = {
  open: boolean;
  handleClose: VoidFunction;
  title: string;
  subtitle: string;
};

function SuccessPopup({ open, handleClose, title, subtitle }: PopupProps) {
  const classes = popupStyle();

  return (
    <div className={classes.popStyle}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.modelStyle}
      >
        <Typography variant="h5">
          <span>
            <CloseIcon onClick={handleClose} />
          </span>
        </Typography>
        <img src={right} alt="right" />
        <Typography variant="h2">{title}</Typography>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Dialog>
    </div>
  );
}

export default SuccessPopup;
