import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import google from '../../../assets/google.svg';
import Loader from '../../../components/loader/index';
import {
  MESSAGES,
  API_ERRORS,
  KEY_CONF,
  URL_CONF,
  ADMIN_ROLE,
  USER_VERIFIED_STATUS,
} from '../../../helper/constants';
import { setEncodedItemDataToLocalStorage } from '../../../helper/storageHelper';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import { IGoogleSSOAuth, IGoogleSSOLoginResponse } from '../../../interfaces/Auth';
import userQueries from '../../../queries/user';
import { PATH_ADMINPANEL, PATH_ONBOARDING, PATH_OTC } from '../../../routers/path';
import { QueryErrorResponse } from '../../../types/UserDetailType';
import { useHandleLogin } from '../../../hooks/useLogin';

function GoogleSSOAuth(props: IGoogleSSOAuth) {
  const { setUserIdData, setShowAccountTypePopup } = props;
  const navigate = useNavigate();
  const handleLogin = useHandleLogin();
  const [loader, setLoader] = useState(false);
  const [ssoCode, setSSOCode] = useState<string>('');
  // Error handling---------------------------------------------
  const handleApiError = ({ response }: QueryErrorResponse) => {
    const message =
      response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
    if (message) {
      toast.error(message);
    }
    return true;
  };

  const {
    data: userData,
    refetch,
    isError,
    error,
  } = useGQLQuery(
    [KEY_CONF.AUTHENTICATION],
    userQueries.GOOGLE_SSO_LOGIN(ssoCode),
    {},
    {
      enabled: !!ssoCode,
    },
    URL_CONF.AUTHENTICATION,
  );

  useEffect(() => {
    if (isError && error !== null) {
      handleApiError(error);
    }
  }, [error, isError]);

  useEffect(() => {
    if (userData && userData.sso_login) {
      const { sso_login: result } = userData as unknown as IGoogleSSOLoginResponse;
      if (result?.open_modal) {
        setUserIdData(result.user_id);
      } else if (result?.access_token && result?.refresh_token) {
        const { access_token: accessToken, refresh_token: refreshToken, info, email } = result;
        handleLogin(accessToken, refreshToken);
        // Encode and save user login information in local storage
        setEncodedItemDataToLocalStorage('userData', JSON.stringify({ ...info, email }));
        toast.success(MESSAGES.USER_LOGIN);
        if (result.info?.role === ADMIN_ROLE) {
          navigate(PATH_ADMINPANEL.children.dashboard);
        } else {
          navigate(
            result.info?.pending_stage === USER_VERIFIED_STATUS
              ? PATH_OTC.dashboard
              : PATH_ONBOARDING.root,
          );
        }
      }
      setLoader(false);
    }
  }, [userData, navigate, handleLogin, setUserIdData, setShowAccountTypePopup]);

  useEffect(() => {
    if (userData && userData.sso_login && ssoCode) {
      const { sso_login: result } = userData as unknown as IGoogleSSOLoginResponse;
      if (result?.open_modal) {
        // Handle the response when there is a need to open a modal
        setShowAccountTypePopup(true);
      }
    }
  }, [userData, ssoCode]);

  useEffect(() => {
    if (ssoCode) {
      refetch();
    }
  }, [ssoCode, refetch]);

  // handling Google SSO--------------------------------------------------------
  const handleGoogleSSOLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (codeResponse: { code: string }) => {
      // Send the authorization code to the backend server
      setLoader(true);
      setSSOCode(codeResponse.code);
    },
    onError: () => {
      // Handle login errors here
      toast.error(API_ERRORS.LOGIN_FAILED);
    },
  });
  //-----------------------------------------------------------------------
  return (
    <>
      {loader && <Loader />}
      <button type="button" onClick={handleGoogleSSOLogin}>
        <img src={google} alt="google" />
      </button>
    </>
  );
}
export default GoogleSSOAuth;
