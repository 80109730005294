import React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import tradeOrderCss from './TradeOrder.style';
import rightImg from '../../../assets/rightArrow.svg';
import { ITradeModal } from '../../../interfaces/User';

function ModalSubmit(props: ITradeModal) {
  const { open, handleClose } = props;
  const classes = tradeOrderCss();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.popupStyle}>
        <Typography
          variant="body2"
          component="span"
          className={classes.cross}
          onClick={handleClose}
        >
          <CloseIcon />
        </Typography>
        <img src={rightImg} alt="" />
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Typography variant="h2" component="h2">
            Congratulations! your order has been placed with Ref ID -121323
          </Typography>
          <Typography variant="body2" component="span" className={classes.smallText}>
            Virtual Pay Admin team will contact you shortly.
          </Typography>
        </Typography>
      </Box>
    </Modal>
  );
}

export default ModalSubmit;
