import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useDashboardStyles from './Dashboard.style';
import { BarChart, Bar, Cell, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ChartData {
  total_amount: string;
  currency: string;
}

interface ChartProps {
  openChart: boolean;
  handleClose: VoidFunction;
  chartData: ChartData[];
}

const chartColor = ['#53B175', '#F6CA19', '#1968B6', '#DD6E55', '#9580C5'];

function AmountTransactedChart({ openChart, handleClose, chartData }: ChartProps) {
  const classes = useDashboardStyles();

  const customTickFormatter = (value: number) => {
    const absValue = Math.abs(value);

    if (absValue >= 1e12) {
      return `${(value / 1e12).toFixed(1)}T`; // Trillion
    }
    if (absValue >= 1e9) {
      return `${(value / 1e9).toFixed(1)}B`; // Billion
    }
    if (absValue >= 1e6) {
      return `${(value / 1e6).toFixed(1)}M`; // Million
    }
    if (absValue >= 1e3) {
      return `${(value / 1e3).toFixed(1)}K`; // Thousand
    }
    return new Intl.NumberFormat('en-US').format(value); // Default formatting for small numbers
  };

  return (
    <div>
      <Dialog
        open={openChart}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.chartPoupStyle}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.tittleCorssStyle}>
              <Typography variant="h2">Amount Transacted</Typography>
              <span>
                <CloseIcon onClick={handleClose} />
              </span>
            </div>
            <BarChart width={700} height={420} barSize={60} data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="currency" />
              <YAxis tickFormatter={customTickFormatter} />
              <Tooltip
                formatter={(value: number) => [new Intl.NumberFormat('en-US').format(value)]} // Custom formatter to include both value and name
              />
              {/* Render each bar with dynamic fill color */}
              <Bar dataKey="total_amount">
                {chartData.map((entry, index) => (
                  <Cell key={entry.currency} fill={chartColor[index % chartColor.length]} />
                ))}
              </Bar>
            </BarChart>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AmountTransactedChart;
