// Function to format the amount with commas
const formatAmount = (v: number, format: string) =>
  v.toLocaleString('en-US', {
    style: 'currency',
    currency: `${format}`,
    maximumFractionDigits: 0, // Sets maximum number of fraction digits to 0
  });

const dateFormatter = ({ dateString }: { dateString: string }) => {
  const timestamp = parseInt(dateString, 10);
  const date = new Date(timestamp);
  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  return formattedDate;
};

const dobFormatter = ({ dateString }: { dateString: string | number }) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
};

// 01/04/2024
const dateFormat = ({ dateString }: { dateString: string }) => {
  const timestamp = parseInt(dateString, 10);
  const date = new Date(timestamp);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
};

const timeFormatter = ({ timeString }: { timeString: string }) => {
  const timestamp = parseInt(timeString, 10);
  const time = new Date(timestamp);
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();

  const formattedTime = `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

  return formattedTime;
};

// timeFormatter like 10:00 AM
const timeFormatterWithAmPm = ({ timeString }: { timeString: string }) => {
  const timestamp = parseInt(timeString, 10);
  const time = new Date(timestamp);
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Handle 0 as 12 in 12-hour format
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

  return formattedTime;
};

export {
  formatAmount,
  dateFormatter,
  dobFormatter,
  timeFormatter,
  timeFormatterWithAmPm,
  dateFormat,
};
