import React, { createContext, useContext, useState, ReactNode, useMemo } from 'react';
import { IOnInformation, IAccountInfoPayout } from '../interfaces/User';

interface FormDataType {
  information: IOnInformation;
  accounInfo: IAccountInfoPayout;
}

interface AuthContextProps {
  formData?: FormDataType;
  setFormData?: (data: FormDataType) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const FormContext = createContext<AuthContextProps | undefined>(undefined);

export function FormProvider({ children }: AuthProviderProps) {
  const [formData, setFormData] = useState<FormDataType | undefined>(undefined);
  const contextValue = useMemo(() => ({ formData, setFormData }), [formData, setFormData]);

  return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
}

export const useFormData = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('error');
  }
  return context;
};
