import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const UserDetailsTabs = makeStyles((theme: Theme) => ({
  inTableRight: {
    margin: '25px 30px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: '5px',
    '& .InovuaReactDataGrid__body': {
      boxShadow: 'none !important',
    },
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    color: '#1F1F1F !important',
    fontSize: '18px !important',
    fontFamily: 'Inter-Medium !important',
    padding: '25px 25px 0px 25px !important',
    '& img': {
      marginRight: '4px',
    },
  },
  container: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '30px',
    },
    width: '80%',
    paddingTop: '43px',
    paddingLeft: '30px',
    '& ul': {
      listStyle: 'none',
      marginTop: '20px',
      '& li': {
        display: 'inline-block',
        marginRight: '7px',
      },
    },
  },
  box: {
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '14px',
    },
    border: '1px solid rgba(211, 211, 211, 1)',
    boxShadow: '0px 4px 9.100000381469727px 0px rgba(109, 109, 109, 0.25)',
    background: '#fff',
    borderRadius: '5px',
    marginBottom: '24px',
    '& h2': {
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '16px',
      },
      color: '#0A2A5B',
      fontSize: '24px',
      fontWeight: '500',
    },
    '& p': {
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '12px',
      },
      fontSize: '14px',
      lineHeight: '23px',
    },
  },
  box1: {
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '14px 12px',
    },
    padding: '24px 45px 24px 24px',
  },
  paragraph: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: '6px',
    },
    margin: '19px auto !important',
  },
  wrapInput: {
    '& fieldset': {
      border: '1px solid rgba(198, 198, 198, 1) !important',
    },
    width: '60%',
    marginRight: '2%',
    '& input': {
      padding: '11.5px 14px',
    },
  },
  btnCopy: {
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '9px 2px !important',
      minWidth: '50px',
    },
    padding: '9px 24px !important',
    '& svg': {
      display: 'none',
      [theme.breakpoints.between('xs', 'sm')]: {
        display: 'block',
      },
    },
  },
  btnText: {
    display: 'block',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'none',
    },
    lineHeight: '24px !important',
    textTransform: 'capitalize',
  },
  editButton: {
    padding: '9px 2px !important',
  },
  box2: {
    padding: '6px 24px',
    '& h2': {
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '85%',
      },
      display: 'inline-block',
      width: '94%',
    },
    '& span': {
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '17px',
      },
      display: 'inline-block',
      padding: '3px 12px',
      fontSize: '24px',
      borderRadius: '20px',
      color: '#1E7F41',
      background: '#DEFFEA',
    },
  },
  box3: {
    '& th': {
      background: '#F2F2F2',
      padding: '10px',
      color: '#7E7E7E',
    },
    '& th:first-child': {
      width: '150px',
      paddingLeft: '24px',
    },
    '& td': {
      padding: '10px',
    },
    '& td:first-child': {
      paddingLeft: '24px',
    },
    '& a': {
      color: '#0D80D4',
    },
  },
  heading: {
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '10px 12px',
    },
    padding: '15px 22px',
  },
  copyButton: {
    color: '#fff !important',
    height: '23px !important',
    fontSize: '15px !important',
  },
  textColour: {
    color: 'green',
    fontSize: '12px',
  },
  errorStyle: {
    color: 'red',
    fontSize: '12px',
  },
}));

export default UserDetailsTabs;
