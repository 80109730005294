import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validators';

// Query to fetch user transactions
const GET_USER_TRANSACTIONS = (skip: number, take: number, search: string) => {
  const searchParam = search ? addEscapeCharacters(search) : '""';
  return gql`
    query {
      userTransactions(skip: ${skip}, take: ${take}, filter:{ref_id:${searchParam}}) {
        data {
          id
          ref_id
          amount
          currency
          blockchain
          crypto_type
          transaction_type
          created_at
        }
        page_info {
          skip
          take
          has_next_page
          total_count
        }
      }
    }
  `;
};

export default {
  GET_USER_TRANSACTIONS,
};
