import React from 'react';
import AccountPayStyle from './AccountPayout.style';
import { Box, Typography, Grid, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

function AccountPayout() {
  const classes = AccountPayStyle();
  const saveAsDraftData = useSelector(
    (state: RootState) => state.userOnboardingStep.saveAsDraftData,
  );
  const accountInfoData = saveAsDraftData?.accountData || [];
  const accountNumber = accountInfoData[0]?.accountNumber;
  const currency = accountInfoData[0]?.currency;
  const institutionNumber = accountInfoData[0]?.institutionNumber;

  return (
    <div>
      <Box className={classes.mainSection}>
        <Grid container spacing={4} className={classes.sectionBody}>
          <Grid item xs={12}>
            <Typography className={classes.subHeaderText} variant="h2">
              {/* <img src={BusinessInformationIcon} alt={BusinessInformationIcon} /> */}
              Account Payout
            </Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Typography variant="body1" className={classes.labelText}>
              Account Number
            </Typography>
            <TextField fullWidth color="secondary" value={accountNumber} disabled />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Typography variant="body1" className={classes.labelText}>
              Currency
            </Typography>
            <TextField fullWidth color="secondary" value={currency} disabled />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Typography variant="body1" className={classes.labelText}>
              Institution Number
            </Typography>
            <TextField fullWidth color="secondary" value={institutionNumber} disabled />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default AccountPayout;
