import { Outlet, useLocation } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import LeftBanner from './components/leftbanner';

function Auth() {
  const location = useLocation();
  const smValue = location.pathname.split('/')[1] === 'signup' ? 4.5 : 6;

  return (
    <div>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={smValue}>
            <LeftBanner loginPageStyle={smValue === 6} />
          </Grid>
          <Outlet />
        </Grid>
      </Box>
    </div>
  );
}
export default Auth;
