import React from 'react';
import popupStyle from './Popup.style';
import Dialog from '@mui/material/Dialog';
import right from '../../../assets/right.svg';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

type PopupProps = {
  id: string;
  open: boolean;
  handleClose: VoidFunction;
};

function PayoutSuccessPopup({ id, open, handleClose }: PopupProps) {
  const classes = popupStyle();

  return (
    <div className={classes.popStyle}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.modelStyle}
      >
        <Typography variant="h5">
          <span>
            <CloseIcon onClick={handleClose} />
          </span>
        </Typography>
        <img src={right} alt="right" />
        <Typography variant="h2">
          Congratulations! your order has <br /> been placed with Ref ID - {id}
        </Typography>
        <Typography variant="subtitle2">
          Virtual Pay Admin team will contact you shortly.
        </Typography>
      </Dialog>
    </div>
  );
}

export default PayoutSuccessPopup;
