import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  selectRadio: {
    '& .MuiFormControlLabel-label': {
      color: '#353535',
      fontSize: '14px !important',
    },
  },
  mainDilogBoxSection: {
    '& .MuiPaper-root': {
      borderRadius: '10px !important',
      minWidth: '25%',
      width: '25%',
      [theme.breakpoints.between('md', 'xl')]: {
        width: '35%',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '80%',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '98%',
      },
    },
    '& .sell': {
      backgroundColor: '#ffdada',
      padding: '4px 19px',
      color: '#bd4a4a',
      borderRadius: '10px',
      textAlign: 'center',
    },
    '& .buy': {
      backgroundColor: 'rgba(222, 255, 234, 1)',
      color: 'rgba(30, 127, 65, 1)',
      padding: '4px 19px',
      borderRadius: '10px',
      textAlign: 'center',
    },
    '& .pending-color': {
      borderRadius: '50px',
      textAlign: 'center',
      backgroundColor: 'rgba(255, 252, 230, 1)',
      color: '#a99323',
      maxWidth: '87px',
      float: 'right',
      padding: '5px 16px',
    },
    '& .completed-color': {
      borderRadius: '50px',
      textAlign: 'center',
      backgroundColor: 'rgba(189, 255, 213, 1)',
      color: 'rgba(30, 127, 65, 1)',
      maxWidth: '87px',
      float: 'right',
      padding: '5px 16px',
    },
    '& .cancelled-color': {
      backgroundColor: 'rgba(255, 189, 189, 1)',
      borderRadius: '50px',
      textAlign: 'center',
      color: '#bd4a4a',
      maxWidth: '87px',
      float: 'right',
      padding: '5px 16px',
    },
    '& .active-color': {
      backgroundColor: 'rgba(255, 189, 189, 1)',
      borderRadius: '10px',
      textAlign: 'center',
      color: '#bd4a4a',
      maxWidth: '87px',
      padding: '2px',
    },
  },
  inTabRight: {
    padding: '12px 24px',
  },
  inBoxTextYellow: {
    color: '#CDB400 !important',
    backgroundColor: '#FFFCE6',
    borderRadius: '66px',
    padding: '5px 16px',
    float: 'right',
  },
  inBoxTextRed: {
    color: '#D80027 !important',
    backgroundColor: '#FFBDBD',
    borderRadius: '66px',
    padding: '5px 16px',
    float: 'right',
  },
  inBoxTextGreen: {
    color: '#054C29 !important',
    backgroundColor: '#BDFFD5',
    borderRadius: '66px',
    padding: '5px 16px',
    float: 'right',
  },
  blueHead: {
    color: '#1A4C8E',
    fontFamily: 'Inter-Regular !important',
    paddingBottom: '4px',
    paddingTop: '4px',
  },
  blSubText: {
    color: '#1F1F1F',
    fontFamily: 'Inter-Medium !important',
  },
  greySubText: {
    color: '#7E7E7E',
    fontFamily: 'Inter-Medium !important',
  },
  whiteText: {
    color: '#ffffff',
  },
  dilogBoxSection: {
    padding: '8px 24px !important',
    color: '#ffffff',
    background: 'linear-gradient(95deg, #0A2A5B -35.11%, #1968B6 100%) !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      color: '#ffffff',
    },
  },
  dialogBody: {
    padding: '0px !important',
    '& input': {
      padding: '6px',
      height: '27px',
      fontSize: '15px',
      borderRadius: '5px',
      border: '1px solid #C6C6C6',
      backgroundColor: '#ffffff',
      color: '#6A6A6A',
      fontFamily: 'Inter-Medium',
    },
    '& .Mui-disabled': {
      backgroundColor: '#E0E0E0',
    },
  },
  sectionBody: {
    '& .MuiFormControl-root': {
      margin: '0px !important',
      minWidth: '100%',
    },
    '& .MuiSelect-select': {
      padding: '6px',
      fontSize: '15px',
      height: '27px !important',
      borderRadius: '5px',
      border: '1px solid #C6C6C6',
      backgroundColor: '#ffffff',
      color: '#6A6A6A',
      alignItems: 'center',
      display: 'flex',
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '16px',
      },
    },
  },
  dilogBoxSectionFooter: {
    padding: '24px !important',
    justifyContent: 'space-between !important',
  },
  footerBtnFillBlue: {
    width: '100%',
    '& button': {
      color: '#fff',
      backgroundColor: '#1968B6',
      borderRadius: '5px',
      cursor: 'pointer',
      textAlign: 'center',
      textTransform: 'capitalize',
      padding: '4px 0px',
      border: '2px solid #1968B6',
      width: 'inherit',
      fontSize: 17,
      fontFamily: 'Inter-Regular',
      '&:hover': {
        backgroundColor: '#1968B6',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: 12,
        width: '100px',
      },
    },
  },
  copyButtonStyle: {
    color: '#1A4C8E',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      color: '#1A4C8E',
      fontSize: '20px',
      cursor: 'pointer',
    },
  },
}));
