import { Box, Typography } from '@mui/material';
import vpLogo from '../../assets/vplogos.svg';
import back from '../../assets/backiconround.svg';
import React from 'react';
import mobileHomePageStyles from './MobileHomePage.style';

function MobileHomePage() {
  const classes = mobileHomePageStyles();
  return (
    <Box className={classes.rightMobileSection}>
      <Box className={classes.rightHeader}>
        <Box className={classes.rightHeaderMobileSection}>
          <Typography variant="h2">
            <span>
              <img src={vpLogo} alt="vpLogo" />
            </span>
          </Typography>
          <Box className={classes.rightBodyButtonSm}>
            <button type="submit">Login</button>
          </Box>
        </Box>
      </Box>

      <Box className={classes.rightBody}>
        <Typography variant="h1" className={classes.mobileHeaderText}>
          Your trusted Crypto Partner
        </Typography>
        <Typography variant="body1" className={classes.mobileHeaderTextSub}>
          Lorem ipsum dolor sit amet consectetur. Bibendum fusce nibh tellus neque pellentesque
          cursus mauris. Est nibh lacus imperdiet eget laoreet. Sapien id et semper nam turpis nulla
          adipiscing. Sit fames tellus ultrices velit cras scelerisque ornare.
        </Typography>
        <Box className={classes.rightBodyButtonDark}>
          <button type="submit">Open Account</button>
        </Box>
        <Box className={classes.bottomSetion}>
          <Typography variant="h2">
            <span>
              <img src={back} alt="back" />
            </span>
            Go back
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default MobileHomePage;
