import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { Box } from '@mui/material';
import { REACT_DATAGRID_KEY } from '../../helper/constants';
import customDataGridStyles from './CustomDataGrid.style';

interface Column {
  name: string;
}
interface IDataGridProps {
  columns: Column[];
  dataSource: any[];
  rowHeight?: number;
  headerHeight?: number;
  gridStyle?: { [key: string]: string | number };
  onCellClick?: (event: any, cellProps: any) => void;
}

function CustomDataGrid({
  columns,
  dataSource,
  rowHeight,
  headerHeight,
  gridStyle,
  onCellClick,
}: IDataGridProps) {
  const classes = customDataGridStyles();
  return (
    <Box
      className={
        columns[0]?.name === 'full_name' ? classes.dataTableStyle : classes.transactionTable
      }
    >
      <ReactDataGrid
        idProperty="id"
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        reorderColumns={false}
        columns={columns}
        dataSource={dataSource}
        style={gridStyle}
        licenseKey={REACT_DATAGRID_KEY}
        onCellClick={onCellClick}
      />
    </Box>
  );
}

export default CustomDataGrid;
