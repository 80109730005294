import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import HistoryIcon from '@mui/icons-material/History';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UserDetailsTabs from './UserDetailsTabs.style';
import toast from 'react-hot-toast';
// import { useGQLQuery } from '../../../hooks/useGQLQuery';
import { MutationResponseType, QueryErrorResponse } from '../../../types/UserDetailType';
import { KEY_CONF, URL_CONF, BASE_URL, ERRORS } from '../../../helper/constants';
import referralQueries from '../../../queries/referral';
import { IReferralData } from '../../../interfaces/Referral';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { decryptString, encryptString } from '../../../helper/encryptionHelper';
import { useGQLMutation } from '../../../hooks/useGQLMutation';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../../components/loader';
import { referralCodeValidator } from '../../../helper/validators';
import { IErrorReferralCode } from '../../../interfaces/User';

function ReferralsTab() {
  const classes = UserDetailsTabs();
  const activeUserLibraryData = useSelector((state: RootState) => state.userLibrary.value);
  const [copiedUrl, setCopiedUrl] = useState<string>('');
  const [prevSelfReferralCodeOrName, setPrevSelfReferralCodeOrName] = useState<string>('');
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<IErrorReferralCode>({
    referralErrorState: '',
  });
  const referralCode = activeUserLibraryData?.self_referral_code ?? '';
  const newReferralCode = copiedUrl.split('/').pop();
  const newReferralCodeName = newReferralCode || '';
  const referralData: IReferralData[] = [];
  const successfulReferral = 0;

  const { mutate, isPending: isLoading } = useGQLMutation(
    KEY_CONF.UPDATE_REFERRAL_CODE,
    referralQueries.UPDATE_REFERRAL_CODE(
      referralCode,
      newReferralCodeName,
      prevSelfReferralCodeOrName,
    ),
    {
      onSuccess: (res: MutationResponseType) => {
        if (res?.update_referral_code_name.success) {
          setIsDisabled(false);
          toast.success('referal code updated successfully');
        }
      },
      onError: ({ response }: QueryErrorResponse) => {
        const errorMessage =
          response && response.errors && response.errors[0]
            ? response.errors[0].message
            : 'API failed';
        if (errorMessage) {
          toast.error(errorMessage);
        }
        setIsDisabled(true);
      },
      enabled: false,
    },
    URL_CONF.ADMIN,
  );
  // Handle copy functionality
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  const validateReferralCode = (value: string) => {
    const error = !referralCodeValidator(value) ? ERRORS.REFERRAL_CODE_ERROR : '';
    setErrorState({
      ...errorState,
      referralErrorState: !referralCodeValidator(value) ? ERRORS.REFERRAL_CODE_ERROR : '',
    });
    return error;
  };

  const handleEdit = () => {
    setIsDisabled(true);
  };

  const handleUpdate = () => {
    const error = validateReferralCode(copiedUrl);
    if (!error) {
      mutate({
        referralCode,
        newReferralCodeName,
        prevSelfReferralCodeOrName,
      });
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCopiedUrl(value);
    setErrorState({
      ...errorState,
      referralErrorState: '',
    });
  };
  // useEffect(() => {
  //     if (listData && listData.adminUserReferrals) {
  //         const decryptedData = listData.adminUserReferrals.data.map((referral) => ({
  //             ...referral,
  //             user_name: decryptString(referral.user_name),
  //         }));
  //         setReferralData(decryptedData);
  //         setSuccessfulReferral(listData.adminUserReferrals.pageInfo?.total_count);
  //     }
  // }, [listData]);

  useEffect(() => {
    const selfReferralCode: string =
      activeUserLibraryData?.self_referral_code_name ||
      activeUserLibraryData?.self_referral_code ||
      '';
    setPrevSelfReferralCodeOrName(selfReferralCode);
    setCopiedUrl(selfReferralCode);
  }, [activeUserLibraryData?.self_referral_code_name, activeUserLibraryData?.self_referral_code]);

  return (
    <div>
      <Box className={classes.container}>
        {isLoading && <Loader />}
        <Box component="div" className={`${classes.box} ${classes.box1}`}>
          <Typography variant="h2" component="h2">
            Become our Partner and Refer
          </Typography>
          <Typography variant="body1" component="p" className={classes.paragraph}>
            {/* Lorem ipsum dolor sit amet consectetur.
                        Massa volutpat vitae risus mauris. Lorem
                        faucibus donec integer habitant amet.
                        Varius ultricies ullamcorper. */}
          </Typography>
          <Grid container spacing={2}>
            <Grid item className={classes.wrapInput} sm={9}>
              <TextField
                size="small"
                label=""
                disabled={!isDisabled}
                value={copiedUrl}
                className="bgwhite"
                fullWidth
                error={!!errorState.referralErrorState}
                onChange={handleInputChange}
              />
              {isCopied && <span className={classes.textColour}>Copied Successfully</span>}
              <span className={classes.errorStyle}>{errorState.referralErrorState}</span>
            </Grid>
            <Grid item sm={3}>
              <Button
                className={classes.btnCopy}
                variant="contained"
                color="primary"
                onClick={!isDisabled ? handleCopy : handleUpdate}
              >
                <ContentCopyIcon />
                <Typography variant="h3" component="h3" className={classes.btnText}>
                  {!isDisabled ? (
                    <CopyToClipboard text={`${BASE_URL}/signup/${copiedUrl}`}>
                      <Typography variant="body2" className={classes.copyButton}>
                        Copy Link
                      </Typography>
                    </CopyToClipboard>
                  ) : (
                    <Typography variant="body2" className={classes.copyButton}>
                      Update Link
                    </Typography>
                  )}
                </Typography>
              </Button>{' '}
              <Button
                className={classes.editButton}
                variant="contained"
                color="primary"
                onClick={handleEdit}
              >
                <EditIcon />
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box component="div" className={`${classes.box} ${classes.box2}`}>
          <Typography variant="h2" component="h2">
            Total Successful referrals
          </Typography>
          <Typography variant="body2" component="span">
            {successfulReferral}
          </Typography>
        </Box>
        <Box component="div" className={`${classes.box} ${classes.box3}`}>
          <Stack direction="row" alignItems="center" gap={1} className={classes.heading}>
            <HistoryIcon />
            <Typography variant="body1">History of Successful referrals</Typography>
          </Stack>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date of Refferal</TableCell>
                  <TableCell>User name</TableCell>
                  <TableCell>No of Successful transactions by this User</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referralData &&
                  referralData.length > 0 &&
                  referralData.map((data: IReferralData) => (
                    <TableRow>
                      <TableCell>
                        {new Date(parseInt(data.date_of_referral, 10)).toLocaleString()}
                      </TableCell>
                      <TableCell>{data.user_name}</TableCell>
                      <TableCell>{data.no_of_transactions}</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  );
}

export default ReferralsTab;
