import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useDataGridStyles = makeStyles((theme: Theme) => ({
  dataTableStyle: {
    padding: '25px 0px',
    '& .InovuaReactDataGrid__header': {
      backgroundColor: '#FAFAFA',
      borderBottom: 'none',
    },
    '& .InovuaReactDataGrid__column-header__content': {
      color: 'rgba(97, 97, 97, 1)',
      fontFamily: 'Inter-Regular',
      fontSize: '14px',
      fontWeight: '400',
      '&:nth-child(1)': {
        paddingLeft: '18px',
      },
      [theme.breakpoints.between(1000, 1200)]: {
        fontSize: '10px',
      },
    },
    '& .InovuaReactDataGrid__row': {
      '& .InovuaReactDataGrid__cell': {
        paddingLeft: '18px',
      },
    },
    '& .InovuaReactDataGrid__cell__content': {
      fontSize: '14px',
      [theme.breakpoints.between(1000, 1200)]: {
        fontSize: '10px',
      },
    },
    '& .InovuaReactDataGrid__cell--first': {
      color: 'rgba(13, 128, 212, 1) !important',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '& .InovuaReactDataGrid__cell': {
      backgroundColor: '#fff',
      borderLeft: 'none',
      color: 'rgba(97, 97, 97, 1)',
      '&:nth-child(7)': {
        '& .InovuaReactDataGrid__cell__content': {
          // backgroundColor: 'rgba(222, 255, 234, 1)',
          borderRadius: '10px',
          // textAlign: 'center',
          // color: 'rgba(30, 127, 65, 1)',
          // maxWidth: '87px',
          padding: '2px',
        },
      },
      '&:nth-child(8)': {
        '& .InovuaReactDataGrid__cell__content': {
          // backgroundColor: 'rgba(255, 252, 230, 1)',
          // borderRadius: '10px',
          // textAlign: 'center',
          // // color: 'rgba(30, 127, 65, 1)',
          // maxWidth: '87px',
          // padding: '2px',
        },
      },
      '& svg': {
        boxShadow: ' 0px 4px 13px 0px rgba(143, 143, 143, 0.25)',
        color: 'rgba(13, 128, 212, 1)',
        cursor: 'pointer',
      },
    },
    '& .InovuaReactDataGrid__body': {
      boxShadow: '0px 4px 13px 0px rgba(143, 143, 143, 0.25)',
    },
    '& .InovuaReactDataGrid': {
      border: 'none',
    },
    '& .InovuaReactDataGrid__column-header__resize-wrapper': {
      borderLeft: 'none',
    },
    '& .InovuaReactDataGrid__column-header__menu-tool': {
      display: 'none',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '15px',
    },
    '& .sell': {
      backgroundColor: '#ffdada',
      padding: '2px',
      color: '#bd4a4a',
      borderRadius: '10px',
      textAlign: 'center',
      maxWidth: '110px',
    },
    '& .buy': {
      backgroundColor: 'rgba(222, 255, 234, 1)',
      color: 'rgba(30, 127, 65, 1)',
      padding: '2px',
      borderRadius: '10px',
      textAlign: 'center',
      maxWidth: '110px',
    },
    '& .pending-color': {
      borderRadius: '10px',
      textAlign: 'center',
      backgroundColor: 'rgba(255, 252, 230, 1)',
      color: '#a99323',
      maxWidth: '87px',
      padding: '2px',
    },
    '& .completed-color': {
      borderRadius: '10px',
      textAlign: 'center',
      backgroundColor: 'rgba(189, 255, 213, 1)',
      color: 'rgba(30, 127, 65, 1)',
      maxWidth: '87px',
      padding: '2px',
    },
    '& .cancelled-color': {
      backgroundColor: 'rgba(255, 189, 189, 1)',
      borderRadius: '10px',
      textAlign: 'center',
      color: '#bd4a4a',
      maxWidth: '87px',
      padding: '2px',
    },
    '& .active-color': {
      backgroundColor: 'rgba(255, 189, 189, 1)',
      borderRadius: '10px',
      textAlign: 'center',
      color: '#bd4a4a',
      maxWidth: '87px',
      padding: '2px',
    },
  },
  actionMenu: {
    padding: '2px',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: '1.5',
    '& .MuiPopover-paper': {
      '& ul': {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        '& li': {
          padding: '10px 28px 10px 12px',
          gap: '12px',
          fontSize: '14px',
          fontFamily: 'Inter-Regular',
        },
        '& li:first-child': {
          borderBottom: '1px solid #E1E1E1',
          color: '#1968B6',
        },
        '& li:nth-child(2)': {
          color: '#53B175',
          borderBottom: '1px solid #E1E1E1',
        },
        '& div': {
          '& li:nth-child(1)': {
            color: 'rgba(0, 144, 69, 1)',
            borderBottom: '1px solid #E1E1E1',
            '& img': {
              width: '13.5%',
            },
          },
          '& li:nth-child(2)': {
            color: 'rgba(195, 0, 0, 1)',
            '& svg': {
              verticalAlign: 'middle',
              fontSize: '20px',
            },
          },
        },
      },
    },
  },
}));

export default useDataGridStyles;
