import { TypeCellProps } from '@inovua/reactdatagrid-community/types';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import HistoryIcon from '../../../assets/history.svg';
// import DataGrid from '../../adminpanel/datagrid';
import { useParams } from 'react-router-dom';
// import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import CustomDataGrid from '../../../components/CustomDataGrid';
import Pagination from '../../../components/PaginationTable';
import Loader from '../../../components/loader';
import { KEY_CONF, RESULT_PER_PAGE, URL_CONF } from '../../../helper/constants';
import { dobFormatter } from '../../../helper/format';
import { getStatusColor, statusHandle, transactionStatus } from '../../../helper/gridCellHelper';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import { IListTransaction, IUserLibrary } from '../../../interfaces/User';
import adminDashboard from '../../../queries/adminDashboard';
import ActionsMenu from '../../adminpanel/datagrid/ActionsMenu';
import TrackOrderFormModel from '../components/trackorderformmodel';
import UpdateTransactionStatusModel from '../components/updatetransactionstatusmodel';
import UserDetailsTabs from './UserDetailsTabs.style';

interface IRowProps {
  value: string;
}

function ListOfTransactionsTab() {
  const classes = UserDetailsTabs();
  const gridStyle = { minHeight: 540 };
  const params = useParams();
  // const activeUserLibraryData = useSelector((state: RootState) => state.userLibrary.value);
  const [openOrderFormModel, setTrackOrderFormModelOpen] = useState(false);
  const [openStatusUpdateModel, setStatusUpdateModelOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSkipValue, setPageSkipValue] = useState(0);
  const [transactionData, setTransactionData] = useState<IListTransaction[]>([]);
  const [tradeOrderData, setTradeOrderDara] = useState<IUserLibrary>();

  const handleClickOpen = () => {
    setTrackOrderFormModelOpen(true);
  };

  const handleClickStatusOpen = () => {
    setStatusUpdateModelOpen(true);
  };

  const userid = params?.userId || '';
  const {
    data: AdminData,
    isFetching,
    refetch,
  } = useGQLQuery(
    KEY_CONF.ADMIN_TRANSACTION,
    adminDashboard.GET_ADMIN_USER_TRANSACTION(userid, pageSkipValue, RESULT_PER_PAGE),
    {},
    {
      refetchOnWindowFocus: false,
    },
    URL_CONF.ADMIN,
  );

  const columns = [
    {
      name: 'created_at',
      header: 'Date of Transaction',
      maxWidth: 1000,
      defaultFlex: 1,
      render: (data: IRowProps) => <div>{dobFormatter({ dateString: Number(data?.value) })}</div>,
    },
    {
      name: 'ref_id',
      header: 'Ref ID',
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      name: 'amount',
      header: 'Amount',
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      name: 'currency',
      header: 'Currency',
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      name: 'crypto_type',
      header: 'Type of crypto',
      maxWidth: 1000,
      defaultFlex: 1,
      render: (data: IRowProps) => <div>{data.value.toUpperCase()}</div>,
    },
    {
      name: 'transaction_type',
      header: 'Buy/Sell',
      maxWidth: 1000,
      defaultFlex: 1,
      render: (data: IRowProps) => (
        <div className={getStatusColor(data?.value)}>{transactionStatus(data?.value)}</div>
      ),
    },
    {
      name: 'updated_at',
      header: 'Date of Completion/ Cancellation',
      maxWidth: 1000,
      defaultFlex: 1,
      render: (data: IRowProps) => <div>{dobFormatter({ dateString: Number(data?.value) })}</div>,
    },
    {
      name: 'transaction_status',
      header: 'Status',
      maxWidth: 1000,
      defaultFlex: 1,
      render: (data: IRowProps) => (
        <div className={getStatusColor(data?.value)}>{statusHandle(data?.value)}</div>
      ),
    },
    {
      name: 'actions',
      header: 'Actions',
      maxWidth: 1000,
      defaultFlex: 1,
      render: () => (
        <ActionsMenu
          openDetailPopup={handleClickOpen || (() => {})}
          openUpdateStatus={handleClickStatusOpen || (() => {})}
        />
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    const skipt = (page - 1) * RESULT_PER_PAGE;
    setPageSkipValue(skipt);
  };

  useEffect(() => {
    refetch();
  }, [pageSkipValue, refetch]);

  useEffect(() => {
    if (AdminData && AdminData?.adminUserTransaction) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { pageInfo, data } = AdminData?.adminUserTransaction;
      const totalCounts = pageInfo?.total_count;
      setTotalCount(totalCounts);
      const totalPage =
        pageInfo && pageInfo.total_count
          ? Math.ceil(pageInfo?.total_count ? pageInfo.total_count / RESULT_PER_PAGE : 0)
          : 0;
      setTotalPages(totalPage);
      setTransactionData(data);
    }
  }, [AdminData]);

  const handleCellClick = (event: any, cellProps: TypeCellProps) => {
    const orderData: IUserLibrary = cellProps.data as IUserLibrary;
    setTradeOrderDara(orderData);
  };

  return (
    <div>
      {isFetching && <Loader />}
      <Box className={classes.inTableRight}>
        <Box>
          <Typography className={classes.tableHeader} variant="h2">
            <img src={HistoryIcon} alt={HistoryIcon} />
            History of transactions
            {/* <Button onClick={handleClickOpen} style={{ padding: '0px', marginLeft: '6px' }}>Trade Order Form</Button>
                        <Button onClick={handleClickStatusOpen} style={{ padding: '0px', marginLeft: '6px' }}>Update Status</Button> */}
          </Typography>
        </Box>
        {/* <DataGrid openDetailPopup={() => {}} valuee={0} /> */}
        <CustomDataGrid
          rowHeight={77}
          headerHeight={46}
          columns={columns}
          dataSource={transactionData}
          gridStyle={gridStyle}
          onCellClick={handleCellClick}
        />
        <Pagination
          totalCount={totalCount}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          resultPerPage={RESULT_PER_PAGE}
        />
      </Box>
      <TrackOrderFormModel
        open={openOrderFormModel}
        setOpen={setTrackOrderFormModelOpen}
        openUpdateStatus={handleClickStatusOpen}
        tradeOrderData={tradeOrderData}
      />
      <UpdateTransactionStatusModel
        open={openStatusUpdateModel}
        setOpen={setStatusUpdateModelOpen}
        tradeOrderData={tradeOrderData}
        refetch={refetch}
        setTrackOrderFormModelOpen={setTrackOrderFormModelOpen}
      />
    </div>
  );
}

export default ListOfTransactionsTab;
